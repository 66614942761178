import { FC, useEffect } from 'react'
import { useTheme } from '@mui/material'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { Box, Button, CustomSwitch } from 'components'
import { unityTheme, Icon, Menu, Input, DataTable } from '@ntpkunity/controls'
import { useStoreContext } from '@store/storeContext'
import {
  useGetAllFinancialInsuranceInitialData,
  useUpdateFinancialInsuranceById,
  useDeleteFinancialInsuranceById
} from '@apis/financial-insurance.service'
import { IFinancialInsurance } from '@models'
import { ActionOptions } from '@helpers/enums'

const tableOptions: any = [
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="EditIcon" />
        {ActionOptions.EDIT}
      </>
    ),
    optionkey: ActionOptions.EDIT,
    optionValue: {}
  },
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="DeleteIcon" />
        {ActionOptions.DELETE}
      </>
    ),
    optionkey: ActionOptions.DELETE,
    optionValue: {}
  }
]

const FinancialInsuranceTable: FC<{
  setPopUpState?: any
  setActionData?: any
  setActionType?: any
}> = ({ setPopUpState, setActionData, setActionType }) => {
  const { states, actions } = useStoreContext()

  const theme = useTheme()
  const { mutate: getAllFinancialInsurance } = useGetAllFinancialInsuranceInitialData()
  const { mutate: updateFinancialInsuranceRow } = useUpdateFinancialInsuranceById()
  const { mutate: deleteFinancialInsuranceRow } = useDeleteFinancialInsuranceById()
  useEffect(() => {
    getAllFinancialInsurance()
  }, [])

  const handleSelection = (_event: any, key: any, value: any) => {
    switch (key) {
      case ActionOptions.EDIT:
        setActionType(ActionOptions.EDIT)
        setPopUpState(true)
        setActionData(value)
        return
      case ActionOptions.DELETE:
        deleteFinancialInsuranceRow({ id: value.id })
        actions.deleteFinancialInsuranceRow({
          ...value
        })
        return
      default:
        return
    }
  }

  return (
    <DataTable
      theme={unityTheme}
      variant="basic"
      sx={{ mb: 3 }}
      theadChildren={
        <>
          <TableRow>
            <TableCell>Provider</TableCell>
            <TableCell>Product Type</TableCell>
            <TableCell>Product Name</TableCell>
            <TableCell>Markup Percentage</TableCell>
            <TableCell>Taxable</TableCell>
            <TableCell>Status</TableCell>
            <TableCell className="action-cell fixed-cell" />
          </TableRow>
          <TableRow className="filters-row">
            <TableCell>
              <Box theme={unityTheme} className="table-filter">
                <Input
                  theme={unityTheme}
                  fullWidth={true}
                  placeholder={'Search...'}
                  type="text"
                  startAdornment={undefined}
                  endAdornment={undefined}
                  id={''}
                />
                <Button defaultBtn iconText={<Icon name="IconFilter" />}></Button>
              </Box>
            </TableCell>
            <TableCell>
              <Box theme={unityTheme} className="table-filter">
                <Input
                  theme={unityTheme}
                  fullWidth={true}
                  placeholder={'Search...'}
                  type="text"
                  startAdornment={undefined}
                  endAdornment={undefined}
                  id={''}
                />
                <Button defaultBtn iconText={<Icon name="IconFilter" />}></Button>
              </Box>
            </TableCell>
            <TableCell>
              <Box theme={unityTheme} className="table-filter">
                <Input
                  theme={unityTheme}
                  fullWidth={true}
                  placeholder={'Search...'}
                  type="text"
                  startAdornment={undefined}
                  endAdornment={undefined}
                  id={''}
                />
                <Button defaultBtn iconText={<Icon name="IconFilter" />}></Button>
              </Box>
            </TableCell>
            <TableCell>
              <Box theme={unityTheme} className="table-filter">
                <Input
                  theme={unityTheme}
                  fullWidth={true}
                  placeholder={'Search...'}
                  type="text"
                  startAdornment={undefined}
                  endAdornment={undefined}
                  id={''}
                />
                <Button defaultBtn iconText={<Icon name="IconFilter" />}></Button>
              </Box>
            </TableCell>
            <TableCell>
              <Box theme={unityTheme} className="table-filter">
                <Input
                  theme={unityTheme}
                  fullWidth={true}
                  placeholder={'Search...'}
                  type="text"
                  startAdornment={undefined}
                  endAdornment={undefined}
                  id={''}
                />
                <Button defaultBtn iconText={<Icon name="IconFilter" />}></Button>
              </Box>
            </TableCell>
            <TableCell>
              <Box theme={unityTheme} className="table-filter">
                <Input
                  theme={unityTheme}
                  fullWidth={true}
                  placeholder={'Search...'}
                  type="text"
                  startAdornment={undefined}
                  endAdornment={undefined}
                  id={''}
                />
                <Button defaultBtn iconText={<Icon name="IconFilter" />}></Button>
              </Box>
            </TableCell>
            <TableCell className="action-cell fixed-cell" />
          </TableRow>
        </>
      }
      tbodyChildren={
        <>
          {states.financialInsuranceInitialData?.map((data: IFinancialInsurance, index: number) => (
            <TableRow className="child-tr" key={index}>
              <TableCell>{data.provider_name}</TableCell>
              <TableCell>{data.product_type}</TableCell>
              <TableCell>{data.product_name}</TableCell>
              <TableCell>{data.markup}%</TableCell>
              <TableCell>{data.taxable ? 'Applicable' : 'Not Applicable'}</TableCell>
              <TableCell>
                <CustomSwitch
                  theme={unityTheme}
                  varient={'basic'}
                  label={data.is_active ? 'Enabled' : 'Disabled'}
                  switchEnabled={data.is_active}
                  onChange={(event) => {
                    updateFinancialInsuranceRow({
                      ...data,
                      is_active: event.target.checked
                    })
                    actions.updateFinancialInsuranceRow({
                      ...data,
                      is_active: event.target.checked
                    })
                  }}
                />
              </TableCell>
              <TableCell className="action-cell fixed-cell">
                <Menu
                  theme={theme}
                  options={tableOptions.map((option: any) => {
                    return {
                      optionText: option.optionText,
                      optionkey: option.optionkey,
                      optionValue: data
                    }
                  })}
                  handleOptionClick={handleSelection}
                  render={(onMenuSelection) => (
                    <Button
                      defaultBtn
                      iconText={<Icon name="MoreIcon" />}
                      onClick={onMenuSelection}
                    ></Button>
                  )}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </>
      }
    />
  )
}
export default FinancialInsuranceTable
