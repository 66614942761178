import { FC, Key, ReactChild, ReactFragment, ReactPortal, useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import { DetailCard } from 'components'
import { Icon, Typography, Grid, Box } from '@ntpkunity/controls'
import { useQueryClient } from 'react-query'
import { AvailableFeeHandlings, InstallationMode, QueryKeys } from '@helpers/enums'
import { useStoreContext } from '@store/storeContext'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import { Status } from '@helpers/enums'
import { FeeEvent } from '@helpers/enums/finance-type.enum'

const PaymentBreakdown: FC<{ order_info: any }> = ({ order_info }) => {
  const [openEpp, setOpenEpp] = useState(false)
  const [openOption, setOpenOption] = useState(false)
  const [openFees, setOpenFees] = useState(false)
  const [openFni, setOpenFni] = useState(false)
  const [openMSP, setOpenMSP] = useState(false)
  const [openNfa, setOpenNfa] = useState(false)
  const [openFaGross, setOpenFaGross] = useState(false)
  const [openCcr, setOpenCcr] = useState(false)
  const theme = useTheme()
  const sortedOptionsArray = order_info?.order_options?.sort((a: any, b: any) =>
    a.installation_mode.localeCompare(b.installation_mode)
  )
  const isValidFloat = (value: any) => typeof value === 'number' && !isNaN(value)
  const base_monthly_payment =
    (isValidFloat(order_info?.estimated_monthly_payment)
      ? order_info.estimated_monthly_payment
      : 0) -
    (isValidFloat(order_info?.monthly_sales_use_tax) ? order_info.monthly_sales_use_tax : 0)
  const queryClient = useQueryClient()
  const { states } = useStoreContext()
  const [defaultCurrency, setDefaultCurrency] = useState('')
  const currencies: any = queryClient.getQueryData([
    QueryKeys.GET_ALL_CURRENCIES,
    states?.lenderInfo?.id
  ])

  let tradeInEquity: any =
    (order_info?.order_tradein?.trade_in_amount && order_info?.order_tradein?.trade_in_amount) -
    (order_info?.order_tradein?.payoff_amount && order_info?.order_tradein?.payoff_amount)

  useEffect(() => {
    const filteredCurrency = currencies?.filter(
      (currency: any) => currency.id === states?.lenderInfo?.default_currency
    )

    setDefaultCurrency(
      filteredCurrency && filteredCurrency.length > 0 ? filteredCurrency[0].symbol : ''
    )
  }, [currencies])

  return (
    <Grid theme={theme} item xl={4} lg={4} md={4} sm={12} xs={12}>
      <DetailCard className="detail-card payment-bd" mb={3}>
        <Box theme={theme} className="card-header">
          <Typography theme={theme} component="h3" variant="h3">
            {order_info?.contract_term}-Months {order_info?.finance_type}
          </Typography>
          <Typography
            theme={theme}
            className="text"
            component="div"
            variant="body2"
            children="Payment Breakdown"
          />

          <Typography
            theme={theme}
            className="text-primary"
            component="div"
            variant="caption"
            mt={2}
          />
        </Box>
        <Box theme={theme} className="line">
          <hr />
        </Box>
        <Box theme={theme} className="card-body">
          <Box theme={theme} mb={2}>
            <Box theme={theme} className="summary-row toggle">
              <Box theme={theme} className="row">
                <Box theme={theme} className="center">
                  <span className="card-text">
                    {order_info?.status == Status.SchedulePickup ||
                    order_info?.status == Status.Appointment ||
                    order_info?.status == Status.Completed ? (
                      <b>Periodic Payment</b>
                    ) : (
                      <b>Estimated Periodic Payment</b>
                    )}
                  </span>
                  {openEpp ? (
                    <Icon
                      name="ChevronDown"
                      className="toggle-btn ch-up"
                      onClick={() => setOpenEpp(!openEpp)}
                    />
                  ) : (
                    <Icon
                      name="ChevronDown"
                      className="toggle-btn"
                      onClick={() => setOpenEpp(!openEpp)}
                    />
                  )}
                </Box>
                <Typography theme={theme} className="card-text" component="span" variant="body2">
                  <b>
                    {order_info?.estimated_monthly_payment
                      ? defaultCurrency +
                        order_info?.estimated_monthly_payment?.toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        })
                      : '-'}
                  </b>
                </Typography>
              </Box>
            </Box>
            <Accordion expanded={openEpp}>
              <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" />
              <AccordionDetails sx={{ ml: 2, mt: 2 }}>
                <Box theme={theme} className="row" mb={2}>
                  <Typography
                    theme={theme}
                    className="card-text text"
                    component="span"
                    variant="body2">
                    Net Periodic Payment
                  </Typography>
                  <Typography
                    theme={theme}
                    className="card-text text"
                    component="span"
                    variant="body2">
                    {base_monthly_payment && base_monthly_payment > 0
                      ? defaultCurrency +
                        base_monthly_payment?.toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        })
                      : '-'}
                  </Typography>
                </Box>
                <Box theme={theme} className="row">
                  <Typography
                    theme={theme}
                    className="card-text text"
                    component="span"
                    variant="body2">
                    Tax Amount
                  </Typography>
                  <Typography
                    theme={theme}
                    className="card-text text"
                    component="span"
                    variant="body2">
                    {order_info?.monthly_sales_use_tax
                      ? defaultCurrency +
                        order_info?.monthly_sales_use_tax?.toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        })
                      : '-'}
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box theme={theme} className="row" mb={2}>
            <Typography theme={theme} className="card-text" component="span" variant="body2">
              <b>Due at Signing</b>
            </Typography>
            <Typography theme={theme} className="card-text" component="span" variant="body2">
              {order_info?.due_at_signing && order_info?.due_at_signing > 0
                ? defaultCurrency +
                  order_info?.due_at_signing?.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })
                : '-'}
            </Typography>
          </Box>
          <Box theme={theme} className="row" mb={2}>
            <Typography theme={theme} className="card-text" component="span" variant="body2">
              Terms
            </Typography>
            <Typography theme={theme} className="card-text" component="span" variant="body2">
              {order_info?.contract_term}-Months
            </Typography>
          </Box>
          <Box theme={theme} className="row" mb={2}>
            <Typography theme={theme} className="card-text" component="span" variant="body2">
              Rate
            </Typography>
            <Typography theme={theme} className="card-text" component="span" variant="body2">
              {order_info?.apr && order_info?.apr?.toFixed(4).toLocaleString()}%
            </Typography>
          </Box>
          {order_info?.finance_type == 'Lease' ? (
            <Box theme={theme} className="row" mb={2}>
              <Typography theme={theme} className="card-text" component="span" variant="body2">
                Annual Mileage
              </Typography>
              <Typography theme={theme} className="card-text" component="span" variant="body2">
                {order_info?.annual_usage && order_info?.annual_usage > 0 ? (
                  <>
                    {order_info?.annual_usage?.toLocaleString()}{' '}
                    <span className="symbol">{states?.lenderInfo?.mileage_unit}</span>
                  </>
                ) : (
                  '-'
                )}
              </Typography>
            </Box>
          ) : (
            ''
          )}
          <Box theme={theme} className="line">
            <hr />
          </Box>
          <Box theme={theme} className="row" mb={2}>
            <Typography theme={theme} className="card-text" component="span" variant="body2">
              Selling Price
            </Typography>
            <Typography theme={theme} className="card-text" component="span" variant="body2">
              {order_info?.order_asset?.unit_price && order_info?.order_asset?.unit_price > 0
                ? defaultCurrency +
                  order_info?.order_asset?.unit_price?.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })
                : '-'}
            </Typography>
          </Box>
          <Box theme={theme} className="row" mb={2}>
            <Typography theme={theme} className="card-text" component="span" variant="body2">
              Down Payment
            </Typography>
            <Typography theme={theme} className="card-text" component="span" variant="body2">
              {order_info?.down_payment && order_info?.down_payment > 0
                ? defaultCurrency +
                  (
                    (order_info?.down_payment * order_info?.order_asset?.unit_price) /
                    100
                  ).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })
                : '-'}
            </Typography>
          </Box>
          {order_info?.order_options && order_info?.order_options?.length > 0 ? (
            <Box theme={theme} mb={2}>
              <Box theme={theme} className="summary-row toggle">
                <Box theme={theme} className="row">
                  <Box theme={theme} className="center">
                    <span className="card-text">
                      <b>Add-Ons</b>
                    </span>

                    {openOption ? (
                      <Icon
                        name="ChevronDown"
                        className="toggle-btn ch-up"
                        onClick={() => setOpenOption(!openOption)}
                      />
                    ) : (
                      <Icon
                        name="ChevronDown"
                        className="toggle-btn"
                        onClick={() => setOpenOption(!openOption)}
                      />
                    )}
                  </Box>
                  <Typography theme={theme} className="card-text" component="span" variant="body2">
                    <b>
                      {defaultCurrency}
                      {order_info?.order_options
                        ?.filter(
                          (option: any) =>
                            (option.installation_mode === InstallationMode.PRE_INSTALLED &&
                              !option.is_price_inclusive) ||
                            option.installation_mode === InstallationMode.OPTIONAL
                        )
                        ?.reduce((sum: any, current: any) => sum + current.applied_price, 0)
                        ?.toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        })}
                    </b>
                  </Typography>
                </Box>
              </Box>
              <Accordion expanded={openOption}>
                <AccordionSummary
                  aria-controls="panel2a-content"
                  id="panel2a-header"></AccordionSummary>
                <AccordionDetails sx={{ ml: 2, mt: 2 }}>
                  {sortedOptionsArray?.length > 0 &&
                    sortedOptionsArray?.map((option: any, index: any) => (
                      <Box theme={theme} key={index}>
                        <>
                          {sortedOptionsArray[index - 1]?.installation_mode !==
                            option?.installation_mode && (
                            <Box theme={theme} className="package-row text" mb={2}>
                              <Typography
                                theme={theme}
                                className="card-text text"
                                component="span"
                                variant="body2">
                                <b>{option?.installation_mode}</b>
                              </Typography>
                            </Box>
                          )}
                          <Box theme={theme} className="row">
                            <Typography
                              theme={theme}
                              className="card-text text"
                              component="span"
                              variant="body2">
                              {option?.product_name}
                            </Typography>
                            <Typography
                              theme={theme}
                              className="card-text text"
                              component="span"
                              variant="body2">
                              {option?.applied_price === 0.0 || option?.is_price_inclusive ? (
                                '-'
                              ) : (
                                <span>
                                  {defaultCurrency}
                                  {option?.applied_price?.toLocaleString(undefined, {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2
                                  })}
                                </span>
                              )}
                            </Typography>
                          </Box>
                        </>
                      </Box>
                    ))}
                </AccordionDetails>
              </Accordion>
            </Box>
          ) : (
            ''
          )}
          {order_info?.order_fees && order_info?.order_fees.length > 0 ? (
            <Box theme={theme} mb={2}>
              <Box theme={theme} className="summary-row toggle">
                <Box theme={theme} className="row">
                  <Box theme={theme} className="center">
                    <span className="card-text">
                      <b>Fees</b>
                    </span>

                    {openFees ? (
                      <Icon
                        name="ChevronDown"
                        className="toggle-btn ch-up"
                        onClick={() => setOpenFees(!openFees)}
                      />
                    ) : (
                      <Icon
                        name="ChevronDown"
                        className="toggle-btn"
                        onClick={() => setOpenFees(!openFees)}
                      />
                    )}
                  </Box>
                  <Typography
                    theme={theme}
                    className="card-text text"
                    component="span"
                    variant="body2">
                    <b>
                      {defaultCurrency}
                      {order_info?.order_fees
                        ?.reduce((sum: any, current: any) => sum + current.applied_price, 0)
                        ?.toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        })}
                    </b>
                  </Typography>
                </Box>
              </Box>
              <Accordion expanded={openFees}>
                <AccordionSummary
                  aria-controls="panel3a-content"
                  id="panel3a-header"></AccordionSummary>

                <AccordionDetails sx={{ ml: 2, mt: 2 }}>
                  {order_info?.order_fees
                    ?.sort((a: any, b: any) => a?.fee_handling?.localeCompare(b?.fee_handling))
                    ?.map((feeData: any, index: number) => (
                      <Box theme={theme} key={index}>
                        <>
                          {order_info?.order_fees[index - 1]?.fee_handling !==
                            feeData?.fee_handling && (
                            <Box theme={theme} className="package-row text" mb={2}>
                              <Typography
                                theme={theme}
                                className="card-text text"
                                component="span"
                                variant="body2">
                                <b>{feeData?.fee_handling}</b>
                              </Typography>
                            </Box>
                          )}
                          <Box theme={theme} className="row" mb={2}>
                            <Typography
                              theme={theme}
                              className="card-text text"
                              component="span"
                              variant="body2">
                              {feeData?.fee_name}
                            </Typography>
                            <Typography
                              theme={theme}
                              className="card-text text"
                              component="span"
                              variant="body2">
                              {feeData?.applied_price === 0.0 ? (
                                '-'
                              ) : (
                                <span>
                                  {defaultCurrency}
                                  {feeData?.applied_price?.toLocaleString(undefined, {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2
                                  })}
                                </span>
                              )}
                            </Typography>
                          </Box>
                        </>
                      </Box>
                    ))}
                </AccordionDetails>
              </Accordion>
            </Box>
          ) : (
            ''
          )}
          {order_info?.order_fnI && order_info?.order_fnI.length > 0 ? (
            <Box theme={theme} mb={2}>
              <Box theme={theme} className="summary-row toggle">
                <Box theme={theme} className="row">
                  <Box theme={theme} className="center">
                    <span className="card-text">
                      <b>Maint. & Service Products</b>
                    </span>

                    {openFni ? (
                      <Icon
                        name="ChevronDown"
                        className="toggle-btn ch-up"
                        onClick={() => setOpenMSP(!openMSP)}
                      />
                    ) : (
                      <Icon
                        name="ChevronDown"
                        className="toggle-btn"
                        onClick={() => setOpenMSP(!openMSP)}
                      />
                    )}
                  </Box>
                  <Typography theme={theme} className="card-text" component="span" variant="body2">
                    <b>
                      {defaultCurrency}
                      {order_info?.order_fnI
                        ?.filter((item: { is_msp: any }) => item.is_msp)
                        ?.reduce(
                          (sum: any, current: { applied_price: any }) =>
                            sum + current.applied_price,
                          0
                        )
                        ?.toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        })}
                    </b>
                  </Typography>
                </Box>
              </Box>
              <Accordion expanded={openMSP}>
                <AccordionSummary
                  aria-controls="panel4a-content"
                  id="panel4a-header"></AccordionSummary>

                <AccordionDetails sx={{ ml: 2, mt: 2 }}>
                  {order_info?.order_fnI
                    ?.filter((fniData: { is_msp: any }) => fniData.is_msp)
                    .map(
                      (
                        fniData: {
                          product_name:
                            | boolean
                            | ReactChild
                            | ReactFragment
                            | ReactPortal
                            | null
                            | undefined
                          applied_price: number
                        },
                        index: Key | null | undefined
                      ) => (
                        <Box theme={theme} className="row" key={index}>
                          <Typography
                            theme={theme}
                            className="card-text text"
                            component="span"
                            variant="body2">
                            {fniData?.product_name}
                          </Typography>
                          <Typography
                            theme={theme}
                            className="card-text text"
                            component="span"
                            variant="body2">
                            {fniData?.applied_price === 0.0 ? (
                              '-'
                            ) : (
                              <span>
                                {defaultCurrency}
                                {fniData?.applied_price?.toLocaleString(undefined, {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2
                                })}
                              </span>
                            )}
                          </Typography>
                        </Box>
                      )
                    )}
                </AccordionDetails>
              </Accordion>
            </Box>
          ) : (
            ''
          )}
          {order_info?.order_fnI && order_info?.order_fnI.length > 0 ? (
            <Box theme={theme} mb={2}>
              <Box theme={theme} className="summary-row toggle">
                <Box theme={theme} className="row">
                  <Box theme={theme} className="center">
                    <span className="card-text">
                      <b>F&I Products</b>
                    </span>

                    <Icon
                      name="ChevronDown"
                      className={`toggle-btn ${openFni ? 'ch-up' : ''}`}
                      onClick={() => setOpenFni(!openFni)}
                    />
                  </Box>
                  <Typography theme={theme} className="card-text" component="span" variant="body2">
                    <b>
                      {defaultCurrency}
                      {order_info?.order_fnI
                        ?.filter((fniData: { is_msp: any }) => !fniData.is_msp)
                        .reduce(
                          (sum: any, current: { applied_price: any }) =>
                            sum + current.applied_price,
                          0
                        )
                        ?.toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        })}
                    </b>
                  </Typography>
                </Box>
              </Box>
              <Accordion expanded={openFni}>
                <AccordionSummary
                  aria-controls="panel4a-content"
                  id="panel4a-header"></AccordionSummary>

                <AccordionDetails sx={{ ml: 2, mt: 2 }}>
                  {order_info?.order_fnI
                    ?.filter((fniData: { is_msp: any }) => !fniData.is_msp)
                    .map(
                      (
                        fniData: {
                          product_name:
                            | boolean
                            | ReactChild
                            | ReactFragment
                            | ReactPortal
                            | null
                            | undefined
                          applied_price: number
                        },
                        index: Key | null | undefined
                      ) => (
                        <Box theme={theme} className="row" key={index}>
                          <Typography
                            theme={theme}
                            className="card-text text"
                            component="span"
                            variant="body2">
                            {fniData?.product_name}
                          </Typography>
                          <Typography
                            theme={theme}
                            className="card-text text"
                            component="span"
                            variant="body2">
                            {fniData?.applied_price === 0.0 ? (
                              '-'
                            ) : (
                              <span>
                                {defaultCurrency}
                                {fniData?.applied_price?.toLocaleString(undefined, {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2
                                })}
                              </span>
                            )}
                          </Typography>
                        </Box>
                      )
                    )}
                </AccordionDetails>
              </Accordion>
            </Box>
          ) : (
            ''
          )}
          {order_info?.order_tradein ? (
            <Box theme={theme} mb={2}>
              <Box theme={theme} className="summary-row toggle">
                <Box theme={theme} className="row">
                  <Box theme={theme} className="center">
                    <span className="card-text">Trade-In Equity/Inequity</span>
                  </Box>
                  <Typography theme={theme} className="card-text" component="span" variant="body2">
                    <b>
                      {tradeInEquity < 0
                        ? `-${defaultCurrency}` +
                          Math.abs(tradeInEquity).toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2
                          })
                        : Math.abs(tradeInEquity).toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2
                          })}
                    </b>
                  </Typography>
                </Box>
              </Box>
              <Accordion>
                <AccordionSummary
                  aria-controls="panel4a-content"
                  id="panel4a-header"></AccordionSummary>

                <AccordionDetails sx={{ ml: 2, mt: 2 }} />
              </Accordion>
            </Box>
          ) : (
            ''
          )}
          <Box theme={theme} mb={2}>
            <Box theme={theme} className="summary-row toggle">
              <Box theme={theme} className="row">
                <Box theme={theme} className="center">
                  <span className="card-text">Taxes Capitalization</span>
                </Box>
                <Typography theme={theme} className="card-text" component="span" variant="body2">
                  {order_info?.tax_amount ? (
                    <>
                      <span className="symbol">{defaultCurrency}</span>
                      {order_info?.tax_amount?.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}
                    </>
                  ) : (
                    '-'
                  )}
                </Typography>
              </Box>
            </Box>
            <Accordion>
              <AccordionSummary
                aria-controls="panel4a-content"
                id="panel4a-header"></AccordionSummary>

              <AccordionDetails sx={{ ml: 2, mt: 2 }} />
            </Accordion>
          </Box>
          <Box theme={theme} className="line">
            <hr />
          </Box>
          <Box theme={theme}>
            <Box theme={theme} className="summary-row toggle">
              <Box theme={theme} className="row">
                <Box theme={theme} className="center">
                  <span className="card-text">
                    <b>NFA/Adjusted Capitalized Cost</b>
                  </span>

                  {openNfa ? (
                    <Icon
                      name="ChevronDown"
                      className="toggle-btn ch-up"
                      onClick={() => setOpenNfa(!openNfa)}
                    />
                  ) : (
                    <Icon
                      name="ChevronDown"
                      className="toggle-btn"
                      onClick={() => setOpenNfa(!openNfa)}
                    />
                  )}
                </Box>
                <Typography theme={theme} className="card-text" component="span" variant="body2">
                  {order_info?.net_finance_amount && order_info?.net_finance_amount > 0
                    ? defaultCurrency +
                      order_info?.net_finance_amount?.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })
                    : '-'}
                </Typography>
              </Box>
            </Box>
            <Accordion expanded={openNfa}>
              <AccordionSummary aria-controls="panel4a-content" id="panel4a-header" />
              <AccordionDetails sx={{ ml: 2, mt: 2 }}>
                <Box theme={theme} mb={2}>
                  <Box theme={theme} className="summary-row toggle">
                    <Box theme={theme} className="row">
                      <Box theme={theme} className="center">
                        <span className="card-text">FA/Gross Capitalized Cost</span>
                        {openFaGross ? (
                          <Icon
                            name="ChevronDown"
                            className="toggle-btn ch-up"
                            onClick={() => setOpenFaGross(!openFaGross)}
                          />
                        ) : (
                          <Icon
                            name="ChevronDown"
                            className="toggle-btn"
                            onClick={() => setOpenFaGross(!openFaGross)}
                          />
                        )}
                      </Box>
                      <Typography
                        theme={theme}
                        className="card-text"
                        component="span"
                        variant="body2">
                        {order_info?.finance_amount ? (
                          <>
                            <span className="symbol">{defaultCurrency}</span>
                            {order_info?.finance_amount?.toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2
                            })}
                          </>
                        ) : (
                          '-'
                        )}
                      </Typography>
                    </Box>
                  </Box>
                  <Accordion expanded={openFaGross}>
                    <AccordionSummary aria-controls="panel4a-content" id="panel4a-header" />
                    <AccordionDetails sx={{ ml: 2, mt: 2 }}>
                      <Box theme={theme} className="row" mb={2}>
                        <Typography
                          theme={theme}
                          className="card-text text text-secondary"
                          component="span"
                          variant="body2"
                          children="Selling Price"
                        />
                        <Typography
                          theme={theme}
                          className="card-text text text-secondary"
                          component="span"
                          variant="body2">
                          {order_info?.order_asset?.unit_price &&
                          order_info?.order_asset?.unit_price > 0
                            ? defaultCurrency +
                              order_info?.order_asset?.unit_price?.toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2
                              })
                            : '-'}
                        </Typography>
                      </Box>
                      <Box theme={theme} className="row" mb={2}>
                        <Typography
                          theme={theme}
                          className="card-text text text-secondary"
                          component="span"
                          variant="body2"
                          children="Add-Ons"
                        />
                        <Typography
                          theme={theme}
                          className="card-text text text-secondary"
                          component="span"
                          variant="body2">
                          {order_info?.order_options && order_info?.order_options.length > 0 ? (
                            <>
                              <span className="symbol">{defaultCurrency}</span>
                              {order_info?.order_options
                                ?.filter(
                                  (option: any) =>
                                    (option.installation_mode === InstallationMode.PRE_INSTALLED &&
                                      !option.is_price_inclusive) ||
                                    option.installation_mode === InstallationMode.OPTIONAL
                                )
                                ?.reduce((sum: any, current: any) => sum + current.applied_price, 0)
                                ?.toLocaleString(undefined, {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2
                                })}
                            </>
                          ) : (
                            '-'
                          )}
                        </Typography>
                      </Box>
                      <Box theme={theme} className="row" mb={2}>
                        <Typography
                          theme={theme}
                          className="card-text text text-secondary"
                          component="span"
                          variant="body2"
                          children="F&I Products"
                        />
                        <Typography
                          theme={theme}
                          className="card-text text text-secondary"
                          component="span"
                          variant="body2">
                          {order_info?.order_fnI && order_info?.order_fnI.length > 0 ? (
                            <>
                              <span className="symbol">{defaultCurrency}</span>
                              {order_info?.order_fnI
                                ?.reduce((sum: any, current: any) => sum + current.applied_price, 0)
                                ?.toLocaleString(undefined, {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2
                                })}
                            </>
                          ) : (
                            '-'
                          )}
                        </Typography>
                      </Box>
                      <Box theme={theme} className="row" mb={2}>
                        <Typography
                          theme={theme}
                          className="card-text text text-secondary"
                          component="span"
                          variant="body2"
                          children="Fees"
                        />
                        <Typography
                          theme={theme}
                          className="card-text text text-secondary"
                          component="span"
                          variant="body2">
                          {order_info?.order_fees && order_info?.order_fees.length > 0 ? (
                            <>
                              <span className="symbol">{defaultCurrency}</span>
                              {order_info?.order_fees
                                ?.filter(
                                  (fee: any) => (
                                    fee?.fee_handling === AvailableFeeHandlings.FINANCED &&
                                    fee?.fee_type !== FeeEvent.ENDOFTERM
                                  )
                                )
                                ?.reduce((sum: any, current: any) => sum + current.applied_price, 0)
                                ?.toLocaleString(undefined, {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2
                                })}
                            </>
                          ) : (
                            '-'
                          )}
                        </Typography>
                      </Box>
                      <Box theme={theme} className="row" mb={2}>
                        <Typography
                          theme={theme}
                          className="card-text text text-secondary"
                          component="span"
                          variant="body2"
                          children="Trade-In Inequity"
                        />
                        <Typography
                          theme={theme}
                          className="card-text text text-secondary"
                          component="span"
                          variant="body2">
                          {tradeInEquity < 0
                            ? `-${defaultCurrency}` +
                              Math.abs(tradeInEquity).toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2
                              })
                            : '-'}
                        </Typography>
                      </Box>
                      <Box theme={theme} className="row">
                        <Typography
                          theme={theme}
                          className="card-text text text-secondary"
                          component="span"
                          variant="body2"
                          children="Taxes Capitalization"
                        />
                        <Typography
                          theme={theme}
                          className="card-text text text-secondary"
                          component="span"
                          variant="body2">
                          {order_info?.tax_amount ? (
                            <>
                              <span className="symbol">{defaultCurrency}</span>
                              {order_info?.tax_amount?.toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2
                              })}
                            </>
                          ) : (
                            '-'
                          )}
                        </Typography>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Box>
                <Box theme={theme}>
                  <Box theme={theme} className="summary-row toggle">
                    <Box theme={theme} className="row">
                      <Box theme={theme} className="center">
                        <span className="card-text">Capitalized Cost Reduction</span>
                        {openCcr ? (
                          <Icon
                            name="ChevronDown"
                            className="toggle-btn ch-up"
                            onClick={() => setOpenCcr(!openCcr)}
                          />
                        ) : (
                          <Icon
                            name="ChevronDown"
                            className="toggle-btn"
                            onClick={() => setOpenCcr(!openCcr)}
                          />
                        )}
                      </Box>
                      <Typography
                        theme={theme}
                        className="card-text"
                        component="span"
                        variant="body2">
                        {order_info?.capitalized_cost_reduction ? (
                          <>
                            <span className="symbol">{defaultCurrency}</span>
                            {order_info?.capitalized_cost_reduction?.toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2
                            })}
                          </>
                        ) : (
                          '-'
                        )}
                      </Typography>
                    </Box>
                  </Box>
                  <Accordion expanded={openCcr}>
                    <AccordionSummary aria-controls="panel4a-content" id="panel4a-header" />
                    <AccordionDetails sx={{ ml: 2, mt: 2 }}>
                      <Box theme={theme} className="row" mb={2}>
                        <Typography
                          theme={theme}
                          className="card-text text text-secondary"
                          component="span"
                          variant="body2"
                          children="Down Payment"
                        />
                        <Typography
                          theme={theme}
                          className="card-text text text-secondary"
                          component="span"
                          variant="body2">
                          {order_info?.down_payment && order_info?.down_payment > 0
                            ? defaultCurrency +
                              (
                                (order_info?.down_payment * order_info?.order_asset?.unit_price) /
                                100
                              ).toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2
                              })
                            : '-'}
                        </Typography>
                      </Box>
                      <Box theme={theme} className="row" mb={2}>
                        <Typography
                          theme={theme}
                          className="card-text text text-secondary"
                          component="span"
                          variant="body2"
                          children="Trade-In Equity"
                        />
                        <Typography
                          theme={theme}
                          className="card-text text text-secondary"
                          component="span"
                          variant="body2">
                          {tradeInEquity > 0
                            ? defaultCurrency +
                              Math.abs(tradeInEquity).toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2
                              })
                            : '-'}
                        </Typography>
                      </Box>
                      <Box theme={theme} className="row">
                        <Typography
                          theme={theme}
                          className="card-text text text-secondary"
                          component="span"
                          variant="body2"
                          children="Rebates"
                        />
                        <Typography
                          theme={theme}
                          className="card-text text text-secondary"
                          component="span"
                          variant="body2">
                          {order_info?.rebate ? (
                            <>
                              <span className="symbol">{defaultCurrency}</span>
                              {order_info?.rebate?.toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2
                              })}
                            </>
                          ) : (
                            '-'
                          )}
                        </Typography>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
      </DetailCard>
    </Grid>
  )
}

export default PaymentBreakdown
