import { useState, FC } from 'react'
import { InputAdornment, styled, useTheme } from '@mui/material'
import { Dialog, DuoTab, Icon, Input, Box, Button, Grid } from '@ntpkunity/controls'
import { useUpdateVehicleDetailByVid } from '@apis/inventory-management.service'
import { useStoreContext } from '@store/storeContext'
import { IVehicleDetail, AddOns } from '@models'
import { InstallationMode } from '@helpers/enums'
import AddNewInventoryOption from '../inventory-options/add-remove-options'
import { Controller, useForm } from 'react-hook-form'

const StickyDuoTab = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'inline-block',
  '.tab-list-underline': {
    backgroundColor: theme.palette.common.white,
    position: 'sticky',
    top: -60,
    left: 0,
    zIndex: 1,
    width: '75%'
  },
  '.TabPanelUnstyled-root': {
    overflow: 'hidden',
    paddingTop: 16,
    marginTop: -16
  },
  '.search': {
    width: '25%',
    display: 'inline-block',
    borderBottom: '1px solid' + theme.palette.divider,
    backgroundColor: theme.palette.common.white,
    position: 'sticky',
    top: -60,
    right: 0,
    zIndex: 1,
    float: 'right',
    paddingLeft: 24,
    paddingTop: 8,
    '.MuiOutlinedInput-notchedOutline': {
      border: '0 solid transparent',
      borderRadius: 0
    },
    '.Mui-focused fieldset': {
      border: '0 solid transparent'
    },
    '.MuiInputAdornment-root': {
      position: 'absolute',
      right: 0,
      zIndex: -1,
      opacity: 0.75
    },
    '.MuiOutlinedInput-input': {
      paddingRight: '15px !important'
    }
  }
}))
const Total = styled(Box)(({ theme }) => ({
  '&.total': {
    backgroundColor: theme.palette.common.white,
    position: 'sticky',
    left: 0,
    bottom: 0,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[900],
    zIndex: 100,
    '.primary': {
      color: theme.palette.primary.main
    },
    hr: {
      opacity: 0.4,
      marginBottom: 0
    }
  }
}))
const InventoryOptionPopup: FC<{
  openPopup: boolean
  vehicleData: IVehicleDetail
  setopenOptionDialog: any
  optionData: AddOns[]
}> = ({ openPopup, vehicleData, setopenOptionDialog, optionData }) => {
  const { actions } = useStoreContext()
  const { mutate: updateInventoryOption } = useUpdateVehicleDetailByVid()

  interface IFormInputs {
    search: string
  }
  const { control } = useForm<IFormInputs>()
  const theme = useTheme()
  const [listChanged, setlistChanged] = useState(false)

  const handleCloseOptionDialog = () => {
    setopenOptionDialog(false)
  }
  const handleOptionData = () => {
    vehicleData.dealer_options = finalOption
  }
  const unique = [...new Set(optionData?.map((item: any) => item.category))]
  const [finalOption, setfinalOption] = useState(vehicleData?.dealer_options)
  const preinstalled = [
    {
      title: 'Pre-Installed',
      content: (
        <AddNewInventoryOption
          setfinalOption={setfinalOption}
          setcategory={optionData?.filter((item: AddOns) => {
            return item.category ? item.installation_mode == 'Pre-Installed' : null
          })}
          vehicleData={vehicleData}
          setlistChanged={setlistChanged}
        />
      )
    }
  ]
  const categories_data = unique?.map((result: any) => {
    {
      return {
        title: result,
        content: (
          <AddNewInventoryOption
            setfinalOption={setfinalOption}
            setcategory={optionData?.filter((item: AddOns) => {
              return item.category
                ? item.category == result &&
                    ((item.installation_mode === InstallationMode.PRE_INSTALLED &&
                      !item.is_price_inclusive) ||
                      item.installation_mode !== InstallationMode.PRE_INSTALLED)
                : null
            })}
            vehicleData={vehicleData}
            setlistChanged={setlistChanged}
          />
        )
      }
    }
  })
  const tabs = preinstalled.concat(categories_data)

  return (
    <Dialog
      disablePortal
      theme={theme}
      variant={undefined}
      size="xl"
      title="Dealer Options"
      open={openPopup}
      onCloseDialog={handleCloseOptionDialog}
      customFooter={
        <>
          <Grid theme={theme} item xs={6}>
            <Button
              theme={theme}
              primary
              text="Save Changes"
              onClick={(_e: any) => {
                handleCloseOptionDialog()
                handleOptionData()
                updateInventoryOption(vehicleData, {
                  onSuccess() {
                    actions.setToast({
                      toastMessage: 'Record Updated Successfully',
                      toastState: true
                    })
                  },
                  onError(error: any) {
                    actions.setToast({
                      toastMessage: error.error.toString(),
                      toastState: true,
                      variant: 'error'
                    })
                  }
                })
              }}
              disabled={!listChanged}
            />
          </Grid>
        </>
      }
    >
      <StickyDuoTab theme={theme}>
        <Box theme={theme} className="search">
          <Controller
            name="search"
            control={control}
            defaultValue={''}
            render={({ field }) => (
              <Input
                theme={theme}
                fullWidth
                type="text"
                placeholder="Search.."
                endAdornment={
                  <InputAdornment position="end">
                    <Icon name="SearchIcon" />
                  </InputAdornment>
                }
                {...field}
              />
            )}
          />
        </Box>
        <DuoTab theme={theme} varient={'underline'} items={tabs} defaultTabIndex={0} />
      </StickyDuoTab>
      <Total theme={theme} className="total" sx={{ pt: 2 }}>
        <Grid theme={theme} spacing={1} container>
          <Grid theme={theme} item xs={6}>
            Total
          </Grid>
          <Grid theme={theme} item xs={6} className="primary" textAlign="right">
            $
            {optionData
              ?.filter((option: AddOns) => finalOption?.includes(option.id))
              .reduce((prev, next) => prev + next.price, 0)}
          </Grid>
          <Grid theme={theme} item xs={12}>
            <hr />
          </Grid>
        </Grid>
      </Total>
    </Dialog>
  )
}

export default InventoryOptionPopup
