import { FC, memo, useEffect, useState } from 'react'
import { styled, useTheme } from '@mui/material'
import { Typography, Icon } from '@ntpkunity/controls'
import OrderLayout from '../create-order/order-layout'
import { IStipulationResponse } from '@models'
import { useLocation } from 'react-router-dom'
import { QueryKeys } from '@helpers/enums'
import { useStoreContext } from '@store/storeContext'
import { useQueryClient } from 'react-query'
import { FinancialStipType } from '@helpers/enums/stipulation-type.enum'

const OrderLayoutWrap = styled('div')((): any => ({
  '.inner-layout-wrap': {
    width: '100%',
    '.inner-customized-container': {
      width: '100%',
      padding: 0
    }
  }
}))

interface IFinancialStipulationProps {
  setOrder?: (data: any) => void
  filteredStips: IStipulationResponse[]
  setIsOrderSave: (data: any) => void
  isOrderSave: boolean
}

const FinancialStipulation: FC<IFinancialStipulationProps> = ({
  setOrder,
  filteredStips,
  setIsOrderSave,
  isOrderSave
}) => {
  const theme = useTheme()
  const queryClient = useQueryClient()
  const { states } = useStoreContext()
  const { pathname } = useLocation()
  const isOrderStip = pathname?.split('/')?.includes('order-stipulation')
  const [toggle, setToggle] = useState<boolean>(true)
  const [defaultCurrency, setDefaultCurrency] = useState('')

  //@ts-ignore
  const currencies: any = queryClient.getQueryData([
    QueryKeys.GET_ALL_CURRENCIES,
    states?.lenderInfo?.id
  ])

  useEffect(() => {
    const filteredCurrency = currencies?.filter(
      (currency: any) => currency.id === states?.lenderInfo?.default_currency
    )
    setDefaultCurrency(
      filteredCurrency && filteredCurrency.length > 0 ? filteredCurrency[0].symbol : ''
    )
  }, [currencies])

  return (
    <>
      <Typography
        className="mini-title"
        component={'p'}
        theme={theme}
        children="Financial Stipulations"
      />
      {filteredStips?.map((stip, index) => (
        <Typography
          key={`financial-${index}`}
          className="notify"
          variant="body2"
          component="p"
          theme={theme}
          onClick={() => setToggle(!toggle)}
        >
          <Icon className="icon" name={stip.fulfilled ? 'GreenCheckIcon' : 'InfoIcon'} />
          The {stip.parameter} should be in between{' '}
          {stip.parameter !== FinancialStipType.TERMS && defaultCurrency}
          {stip.parameter !== FinancialStipType.TERMS
            ? stip?.value_from?.toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
              })
            : stip?.value_from}{' '}
          and {stip.parameter !== FinancialStipType.TERMS && defaultCurrency}
          {stip.parameter !== FinancialStipType.TERMS
            ? stip?.value_to?.toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
              })
            : stip?.value_to}
        </Typography>
      ))}
      <OrderLayoutWrap hidden={toggle}>
        <OrderLayout
          setOrder={setOrder}
          setIsOrderSave={setIsOrderSave}
          isOrderSave={isOrderSave}
          isOrderStip={isOrderStip}
        />
      </OrderLayoutWrap>
    </>
  )
}

export default memo(FinancialStipulation)
