import Http from '@helpers/http-api'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  EP_GET_CONFIGURED_QUOTATION,
  EP_GET_QUOTATION_FEE,
  EP_SAVE_ORDER_QUOTATION,
  EP_ORDER_EXPIRY_DATE,
  EP_GET_SUBMITTED_ORDER_INFORMATION,
  EP_GET_PROGRAMS,
  EP_ORDER_BY_ID,
  EP_UPLOAD_DOCUMENT,
  EP_UPLOAD_DOCUMENT_BY_REFERENCE_NUMBER,
  EP_UPLOAD_FILE,
  EP_VERIFY_DOCUMENT,
  EP_GET_ORDER_INFORMATION,
  EP_CREDIT_REQUEST,
  EP_UPDATE_PAYMENT_STATUS,
  EP_CONVERT_QUOTATION_TO_APPLICATION,
  EP_GET_ALL_QUOTATIONS,
  EP_GET_ALL_ORDERS,
  EP_GET_ORDER_BY_CUSTOMER,
  EP_UPDATE_ORDER_TO_QUOTATION,
  EP_UPLOAD_DOCUMENT_by_CUSTOMER_REFERENCE,
  EP_SUBMIT_ORDER,
  EP_GET_ALL_ORDERS_DOC_PACKAGES,
  EP_SIGN_DOCUMENT_BY_ROLE,
  EP_GET_PRE_SIGNED_URL,
  EP_SET_TRADE_IN_INSPECTION_STATUS,
  EP_UPDATE_ORDER_ASSET,
  EP_ORDER_DOCUMENT,
  EP_ORDER_DOCUMENT_FNI,
  EP_DELETE_DOCUMENT_BY_IDENTIFIER,
  EP_FETCH_INSURANCE_DETAILS,
  EP_RESUBMIT_CONDITIONED_ORDER,
  EP_GET_ALL_ORDER_DOCUMENTS,
  EP_UPDATE_INSURANCE_INFORMATION,
  EP_ADD_INSURANCE_DETAILS,
  DMS_CUSTOMER_DISCLAIMER_AGREEMENT,
  DISCLAIMER_AGREEMENT
} from '@helpers/endpoints'
import { IDocumentPayload, IUploadFile, IOffer, IOrderFee } from '@models'
import { getMarketPlaceToken } from '@helpers/methods'
import { QueryKeys } from '@helpers/enums'
const token = getMarketPlaceToken()
const headers = {
  Authorization: `Bearer ${token}`
}

export const useUploadFile = (): any => {
  const { data, isLoading, mutate } = useMutation<IUploadFile, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.post<any>(`${EP_UPLOAD_FILE}/${body.identifier}`, body)
    },
    {
      onSuccess: async () => {}
    }
  )
  return { data, isLoading, mutate }
}

export const useVerifyDocument = (): any => {
  const { data, isLoading, mutate } = useMutation((identifier: string) => {
    const apiService = Http.createConnection()
    return apiService.post<any>(`${EP_VERIFY_DOCUMENT}/${identifier}`)
  })
  return { data, isLoading, mutate }
}

export const useGetOrderById = (reference_number: string) => {
  const { data, isLoading, error } = useQuery(
    `${EP_UPLOAD_DOCUMENT_BY_REFERENCE_NUMBER}/reference_number=${reference_number}`,
    async () => {
      const apiService = Http.createConnection()
      return await apiService.get(
        `${EP_UPLOAD_DOCUMENT_BY_REFERENCE_NUMBER}/reference_number=${reference_number}`
      )
    }
  )
  return { data, isLoading, error }
}

export const useSaveConfiguredQuotation = (): any => {
  const { data, isLoading, mutate } = useMutation<IOffer, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.post<IOffer>(`${EP_GET_CONFIGURED_QUOTATION}`, body)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useGetQuotation = (): any => {
  const { data, isLoading, error, mutate } = useMutation(
    () => {
      const apiService = Http.createConnection()
      return apiService.get<any>(EP_GET_CONFIGURED_QUOTATION)
    },
    {
      onSuccess(_response: any) {}
    }
  )
  return { data, isLoading, error, mutate }
}

export const useGetExpiryDate = (): any => {
  const { data, isLoading, error, mutate } = useMutation(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.get<any>(
        `${EP_ORDER_EXPIRY_DATE}/${body?.order_state}/${body?.dealer_id}`,
        {},
        { headers }
      )
    },
    {
      onSuccess(_response: any) {}
    }
  )
  return { data, isLoading, error, mutate }
}

export const useGetQuotationById = (): any => {
  const { data, isLoading, mutate } = useMutation<{ id: number }, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.get<any>(`${EP_GET_CONFIGURED_QUOTATION}/${body.id}`)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useDeleteQuotationById = (): any => {
  const { data, isLoading, mutate } = useMutation<{ id: number }, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.delete<any>(`${EP_GET_CONFIGURED_QUOTATION}/${body.id}`)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}
export const useUpdateQuotationById = (): any => {
  const queryClient = useQueryClient()
  const { data, isLoading, mutate, error, mutateAsync } = useMutation<{ body: any }, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.patch<any>(`${EP_GET_CONFIGURED_QUOTATION}/${body.identifier}`, body)
    },
    {
      onSuccess() {
        queryClient.invalidateQueries('Order')
      }
    }
  )
  return { data, isLoading, mutate, error, mutateAsync }
}
export const useReSubmitOrderById = (): any => {
  const { data, isLoading, mutate, error, mutateAsync } = useMutation<{ body: any }, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.patch<any>(`${EP_RESUBMIT_CONDITIONED_ORDER}/${body.identifier}`, body)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate, error, mutateAsync }
}
export const useConvertQuotationToApplication = (): any => {
  const queryClient = useQueryClient()
  const { data, isLoading, mutate, error } = useMutation<{ body: any }, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.patch<any>(
        `${EP_CONVERT_QUOTATION_TO_APPLICATION}/${body.identifier}`,
        body
      )
    },
    {
      onSuccess() {
        queryClient.invalidateQueries('Order')
      }
    }
  )
  return { data, isLoading, mutate, error }
}
export const useSaveConfigurationFee = (): any => {
  const { data, isLoading, mutate } = useMutation<IOrderFee, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.post<IOrderFee>(`${EP_GET_QUOTATION_FEE}`, body)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useGetQuotationFeeByOrderId = (): any => {
  const { data, isLoading, mutate } = useMutation<{ order_id: number }, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      body.order_id ? `${body.order_id}` : ''
      return apiService.get<any>(`${EP_GET_QUOTATION_FEE}/${body.order_id}`)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useSaveOrderRequest = (): any => {
  const { data, isLoading, mutate, error } = useMutation<any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.post<any>(`${EP_SAVE_ORDER_QUOTATION}`, body)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate, error }
}

export const useGetProgramInformation = (): any => {
  return useMutation<any>(
    (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.API_GATEWAY
      })
      return apiService.post<any>(`${EP_GET_PROGRAMS}`, body)
    },
    {
      onSuccess() {}
    }
  )
}

export const useGetSubmittedOrderInformation = (): any => {
  const { data, isLoading, mutate } = useMutation<
    { reference_number: string; order_status: string },
    any
  >(
    (body: any) => {
      const apiService = Http.createConnection()
      const params = `page_number=0&page_size=10&reference_number=${body.reference_number}&order_status=${body.order_status}`
      return apiService.get<any>(`${EP_GET_SUBMITTED_ORDER_INFORMATION}?${params}`, {})
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useGetDocumentById = (orderId: number) => {
  const url = `${EP_ORDER_BY_ID}?order_id=${orderId}`
  const { data, isLoading, error } = useQuery(url, async () => {
    const apiService = Http.createConnection()
    return await apiService.get(url)
  })
  return { data, isLoading, error }
}

export const useUploadDocument = (): any => {
  const { data, isLoading, mutate } = useMutation<IDocumentPayload, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.post<any>(EP_UPLOAD_DOCUMENT, body)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useUpdateDocument = (): any => {
  const { data, isLoading, mutate } = useMutation<IDocumentPayload, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.patch<any>(
        `${EP_UPLOAD_DOCUMENT_by_CUSTOMER_REFERENCE}/${body?.[0]?.customer_reference_id}`,
        body
      )
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useDeleteDocument = (): any => {
  const { data, isLoading, mutate } = useMutation<IDocumentPayload, any>(
    ({ document_identifier, ...body }: any) => {
      const apiService = Http.createConnection()
      return apiService.patch<any>(
        `${EP_DELETE_DOCUMENT_BY_IDENTIFIER}?document_identifier=${document_identifier}`,
        body
      )
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useOrderDocument = (): any => {
  const { data, isLoading, mutateAsync } = useMutation<any, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.post<any>(EP_ORDER_DOCUMENT, body)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutateAsync }
}

export const useOrderFnIDocument = (): any => {
  const { data, isLoading, mutateAsync } = useMutation<any, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.post<any>(EP_ORDER_DOCUMENT_FNI, body)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutateAsync }
}

export const useCreditResponse = (): any => {
  const { data, isLoading, mutate } = useMutation<any, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.post<any>(EP_CREDIT_REQUEST, body)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useOrderSubmit = (): any => {
  const { data, isLoading, mutate } = useMutation<any, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.post<any>(
        `${EP_SUBMIT_ORDER}?reference_number=${body?.referenceNumber}`,
        body
      )
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useGetOrderInformation = (): any => {
  const queryClient = useQueryClient()
  const { data, isLoading, mutate } = useMutation<any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.get<any>(`${EP_GET_ORDER_INFORMATION}/${body.reference_number}`, {})
    },
    {
      onSuccess(data, variables: any) {
        queryClient.setQueryData(['Order', variables.reference_number], data)
      },
      retry: 3
    }
  )
  return { data, isLoading, mutate }
}

export const useUpdatePaymentStatus = (reference_number: string): any => {
  const { data, isLoading, mutate } = useMutation<any>(
    (body: any) => {
      const apiService = Http.createConnection()
      if (body?.order_status && body?.payment_status) {
        return apiService.patch<any>(
          `${EP_UPDATE_PAYMENT_STATUS}/${reference_number}?order_status=${body?.order_status}&payment_indicator=${body?.payment_status}`,
          body
        )
      } else {
        return apiService.patch<any>(
          `${EP_UPDATE_PAYMENT_STATUS}/${reference_number}?payment_indicator=${body?.payment_status}`,
          body
        )
      }
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}
export const useGetAllQuotations = (): any => {
  const { data, isLoading, mutate } = useMutation<any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.get<any>(`${EP_GET_ALL_QUOTATIONS}/${body.reference_number}`, {})
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}
export const getAllOrdersByReferenceNumberFromServer = (columnFilters: string) => {
  const apiService = Http.createConnection()
  return apiService.get<any>(`${EP_GET_ALL_ORDERS}/?${columnFilters}`, {}, { headers })
}

export const UseQuery_GetAllOrdersByReferenceNumber = (columnFilters: string) => {
  return useQuery(
    QueryKeys.GET_ALL_ORDERS,
    async () => {
      return getAllOrdersByReferenceNumberFromServer(columnFilters).then((data) => {
        return data
      })
    },
    { enabled: !!columnFilters }
  )
}
export const useGetAllOrdersByReferenceNumber = (): any => {
  const { data, isLoading, mutate } = useMutation<any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.get<any>(`${EP_GET_ALL_ORDERS}${body.reference_number}`, {})
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}
export const useGetAllOrderDocumentPackagesByReferenceNumber = (): any => {
  const { data, isLoading, mutate } = useMutation<any>((body: any) => {
    const apiService = Http.createConnection()
    const params = body ? `?${body}` : ``
    return apiService.get<any>(`${EP_GET_ALL_ORDERS_DOC_PACKAGES}${params}`, {})
  })
  return { data, isLoading, mutate }
}
export const useGetCustomerOrders = (): any => {
  const { data, isLoading, mutate } = useMutation<any>(
    (body: any) => {
      const apiService = Http.createConnection()
      const params = body ? `?${body}` : ``
      return apiService.get<any>(`${EP_GET_ORDER_BY_CUSTOMER}${params}`, {})
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useConvertApplicationToQuotation = (): any => {
  const { data, isLoading, mutate, error } = useMutation(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.patch<any>(
        `${EP_UPDATE_ORDER_TO_QUOTATION}/${body.identifier}/${body.reference_number}`
      )
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate, error }
}

export const useSignDocumentPackage = (): any => {
  const { data, isLoading, mutate } = useMutation<IDocumentPayload, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.post<any>(
        `${EP_SIGN_DOCUMENT_BY_ROLE}/${body?.document_package_identifier}/${body?.document_identifier}/${body?.signature_id}/${body.signer_role}?date_time_format=YYYY%2FMM%2FDD&time_zone=UTC`
      )
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}
export const useGetPreSignedUrl = (): any => {
  const { data, isLoading, mutate } = useMutation<any>((body: any) => {
    const apiService = Http.createConnection()
    return apiService.get<any>(`${EP_GET_PRE_SIGNED_URL}/${body.key}`, {})
  })
  return { data, isLoading, mutate }
}

export const useSetTradeInInspectionStatus = (): any => {
  const { data, isLoading, mutate } = useMutation<any, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.patch<any>(
        `${EP_SET_TRADE_IN_INSPECTION_STATUS}/${body?.orderId}?inspection_status=${body?.inspectionStatus}`
      )
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useHandleSubmitOdometerForm = (): any => {
  const queryClient = useQueryClient()
  const { data, isLoading, mutate } = useMutation<any>(
    ({ referenceNumber, ...payload }: any) => {
      const apiService = Http.createConnection()
      return apiService.patch<any>(`${EP_UPDATE_ORDER_ASSET}/${referenceNumber}`, payload)
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.DISCLAIMER_AGREEMENT)
      }
    }
  )
  return { data, isLoading, mutate }
}

export const useAddInsuranceDetail = (): any => {
  const queryClient = useQueryClient()
  const { data, isLoading, mutate } = useMutation<any, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.post<any>(EP_FETCH_INSURANCE_DETAILS, body)
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_ORDER_DOCUMENTS)
      }
    }
  )
  return { data, isLoading, mutate }
}

export const useAddInsuranceInformation = (): any => {
  const queryClient = useQueryClient()
  const { data, isLoading, mutate } = useMutation<any, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.post<any>(EP_ADD_INSURANCE_DETAILS, body)
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_ORDER_DOCUMENTS)
      }
    }
  )
  return { data, isLoading, mutate }
}

export const useUpdateInsuranceDetail = (): any => {
  const { data, isLoading, mutate } = useMutation<any, any>(
    ({ reference_id, ...payload }: any) => {
      const apiService = Http.createConnection()
      return apiService.patch<any>(
        `${EP_FETCH_INSURANCE_DETAILS}?reference_id=${reference_id}&order_reference_id=${payload.order_reference_id}`,
        payload
      )
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useUpdateInsuranceInformation = (): any => {
  const queryClient = useQueryClient()
  const { data, isLoading, mutate } = useMutation<any, any>(
    ({ reference_id, order_reference_id, ...payload }: any) => {
      const apiService = Http.createConnection()
      return apiService.patch<any>(
        `${EP_UPDATE_INSURANCE_INFORMATION}?reference_id=${reference_id}&order_reference_id=${order_reference_id}`,
        payload
      )
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_ORDER_DOCUMENTS)
      }
    }
  )
  return { data, isLoading, mutate }
}

export const useGetOrderInsuranceDocs = (order_reference_id?: string): any => {
  return useQuery(
    [QueryKeys.GET_ALL_ORDER_DOCUMENTS, order_reference_id],
    async () => {
      const apiService = Http.createConnection()
      try {
        const response = await apiService.get<any>(
          `${EP_GET_ALL_ORDER_DOCUMENTS}/${order_reference_id}`,
          {}
        )
        return response
      } catch (error) {}
    },
    { enabled: !!order_reference_id }
  )
}

export const useAddContractDocument = (): any => {
  const { data, isLoading, mutate } = useMutation<any, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      const updated_url = body.document_event.replace('%20', '+')
      return apiService.post<any>(
        `contract-document?document_event=${updated_url}&reference_number=${body.reference_number}`,
        {}
      )
    },
    {
      onSuccess: () => {}
    }
  )
  return { data, isLoading, mutate }
}

export const useAddDisclaimerAgreement = (): any => {
  const { data, isLoading, mutate } = useMutation<any, any>((body: any) => {
    const apiService = Http.createConnection()
    return apiService.post<any>(DMS_CUSTOMER_DISCLAIMER_AGREEMENT, body)
  })
  return { data, isLoading, mutate }
}

export const useGetDisclaimersAgreement = (
  order_reference_id?: string,
  customer_reference_id?: string
) => {
  const { data, isLoading, error } = useQuery(
    ['disclaimersAgreement', order_reference_id, customer_reference_id],
    () => {
      const apiService = Http.createConnection()
      return apiService.get<any>(
        `${DISCLAIMER_AGREEMENT}?customer_reference_id=${customer_reference_id}&order_reference_id=${order_reference_id}`
      )
    },
    {
      enabled: !!order_reference_id && !!customer_reference_id
    }
  )
  return {
    data,
    isLoading,
    error
  }
}
