import { useMutation, useQuery, useQueryClient } from 'react-query'
import Http from '@helpers/http-api'
import { useStoreContext } from '@store/storeContext'
import { EP_GET_WORK_QUEUE, EP_CANCEL_ORDER } from '@helpers/endpoints'

import { getMarketPlaceToken } from '@helpers/methods'
import { QueryKeys } from '@helpers/enums'

const token = getMarketPlaceToken()
const headers = {
  Authorization: `Bearer ${token}`
}
export const useGetWorkOrder = (): any => {
  const { actions } = useStoreContext()
  const { data, isLoading, error, mutate } = useMutation<{}, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      let params = body.data ? `${body.data}` : ''
      return apiService.get<any>(
        params ? `${EP_GET_WORK_QUEUE}?${params}` : EP_GET_WORK_QUEUE,
        {},
        { headers }
      )
    },
    {
      onSuccess(_response: any) {
        actions.setDealerFeeInitialData(_response)
      }
    }
  )
  return { data, isLoading, error, mutate }
}

const getAllWQOrders = (columnFilters: string) => {
  let userRole = null
  let email = null
  if (localStorage.getItem('settings') !== null) {
    var access_token_local = JSON.parse(localStorage.getItem('settings') || '')
    userRole = access_token_local?.role
    email = access_token_local?.email ? access_token_local?.email : access_token_local?.user_name
  }

  if (userRole?.name == 'DealerAdmin' || userRole?.name === 'Index Dealer Admin User') {
    columnFilters = `${columnFilters}&dealer_email=${email}`
  }
  const apiService = Http.createConnection()
  return apiService.get<any>(`${EP_GET_WORK_QUEUE}?${columnFilters}`, {}, { headers })
}

export const UseQuery_Get_Work_Order = (columnFilters: string) => {
  return useQuery(
    [QueryKeys.GET_ALL_WORK_ORDERS, columnFilters],
    async () => {
      return getAllWQOrders(columnFilters)
    },
    { keepPreviousData: true }
  )
}

export const UseQuery_Get_ScheduledPickupOrder = (columnFilters: string) => {
  return useQuery(
    [QueryKeys.GET_ALL_SCHEDULE_PICKUP_ORDERS, columnFilters],
    async () => {
      return getAllWQOrders(columnFilters)
    },
    { keepPreviousData: true }
  )
}

export const UseQuery_Get_PendingConfirmationOrder = (columnFilters: string) => {
  return useQuery(
    [QueryKeys.GET_ALL_PENDING_CONFIRMATION_ORDERS, columnFilters],
    async () => {
      return getAllWQOrders(columnFilters)
    },
    { keepPreviousData: true }
  )
}

export const UseQuery_Get_CancelledOrder = (columnFilters: string) => {
  return useQuery(
    [QueryKeys.GET_ALL_CANCELLED_ORDERS, columnFilters],
    async () => {
      return getAllWQOrders(columnFilters)
    },
    { keepPreviousData: true }
  )
}

export const UseQuery_Get_CompletedOrder = (columnFilters: string) => {
  return useQuery(
    [QueryKeys.GET_ALL_COMPLETED_ORDERS, columnFilters],
    async () => {
      return getAllWQOrders(columnFilters)
    },
    { keepPreviousData: true }
  )
}

export const UseQuery_Get_AppointmentOrders = (columnFilters: string) => {
  return useQuery(
    [QueryKeys.GET_ALL_APPOINTMENT_ORDERS, columnFilters],
    async () => {
      return getAllWQOrders(columnFilters)
    },
    { keepPreviousData: true }
  )
}

export const useMutationCancelOrder = () => {
  const queryClient = useQueryClient()
  return useMutation(
    (reference_number: any) => {
      const apiService = Http.createConnection()
      return apiService.patch<any>(EP_CANCEL_ORDER + reference_number)
    },
    {
      onSuccess: async (_response: any) => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_WORK_ORDERS)
      }
    }
  )
}

export const useCreditDecision = () => {
  const queryClient = useQueryClient()
  return useMutation(
    (body: any) => {
      const { reference_number, data } = body
      const apiService = Http.createConnection()
      return apiService.patch(`${EP_CANCEL_ORDER}/${reference_number}`, data)
    },
    {
      onSuccess: async (_response: any) => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_WORK_ORDERS)
      }
    }
  )
}
