import { FC, useEffect, useState } from 'react'
import { Box } from 'components'
import 'react-toastify/dist/ReactToastify.css'
import { OrderQuotationComparison } from '@app/order-management'
import { NoCalculationData } from '@app/no-quote'
import { useStoreContext } from '@store/storeContext'
import {
  useGetVehicleIdUsingModelCode,
  useOrderCalculationRequest
} from '@apis/inventory-management.service'
import { useGetProgramInformation } from '@apis/order-management.service'
import useToastFunction from '@hooks/useToast'
import {
  useGetAllowedMileage,
  useGetContractTerms,
  useGetRating
} from '@apis/configurations.service'
import {
  useGetAllDealerFeebyState,
  useGetPreferenceByDealerCode
} from '@apis/dealer-configurations.service'
import { DEFAULT_RENTAL_FREQUENCY } from '@helpers/constants'
import { IVehicle } from '@models'
import {
  // AssetUsage,
  InstallationMode,
  OrderState,
  QueryKeys,
  ScheduleOptions,
  FeeHandlingType
} from '@helpers/enums'
import NotQuotation from './no-quotation'
import { useQueryClient } from 'react-query'

const CreateOrderQuotation: FC<{
  setPopUpState: any
  sendSaveOrderRequest: () => void
  orderStatus?: string
  vehicle: IVehicle
  programCalculationData: any
  setProgramCalculationData: any
  maxCreditRating: any
  setMaxCreditRating: (data: any) => unknown
  setQuotationParameterData: (data: any) => unknown
  quotationParameterData: any
  quotationObject: any
  setOrderStage: (data: any) => unknown
  setCalculationPayload: (data: any) => unknown
  setQuoteCalculate: (data: any) => unknown
  quoteCalculate: boolean
  orderId: string
  orderStage: string
  orderObject: any
  setOrderObject: any
  mode: string
  setProgramResponse: any
  customerData: any
  dealerProfile: any
}> = ({
  setPopUpState,
  orderStatus,
  vehicle,
  programCalculationData,
  setProgramCalculationData,
  setMaxCreditRating,
  setQuotationParameterData,
  quotationParameterData,
  quotationObject,
  setOrderStage,
  setCalculationPayload,
  setQuoteCalculate,
  quoteCalculate,
  sendSaveOrderRequest,
  orderStage,
  orderObject,
  setOrderObject,
  orderId,
  mode,
  customerData,
  setProgramResponse,
  dealerProfile
}) => {
    const [defaultMileageValue, setDefaultMileageValue] = useState()
    const { states } = useStoreContext()
    const [mileageData, setMileageData] = useState<any[]>([])
    const {
      mutate: getProgramInformationRequest,
      isLoading: programReqLoading,
      error: programReqError,
      data: programData
    } = useGetProgramInformation()
    const { mutate: getAllDealerFee, data: allDealerFeeData } = useGetAllDealerFeebyState()
    const { mutate: getContractTerms, data: contractTermsData } = useGetContractTerms()
    const { mutate: getAllowedMileage, data: allowedMileageData } = useGetAllowedMileage()
    const { mutate: getCreditRating, data: creditRatingData } = useGetRating()
    const { mutate: getPreference } = useGetPreferenceByDealerCode()
    const queryClient = useQueryClient()
    const stateData: any = queryClient.getQueryData(QueryKeys.GET_ALL_STATES)
    const garagingAddresses: any[] =
      customerData?.customer_addresses?.filter(
        (address: any) => address?.address_type === 'Garaging'
      ) || []
    const mailingAddresses: any[] =
      garagingAddresses?.length > 0
        ? garagingAddresses
        : customerData?.customer_addresses?.filter(
          (address: any) => address?.address_type === 'Mailing' && address?.is_garaging === true
        ) || []
    useEffect(() => {
      if (quoteCalculate) {
        reCalculateQuote()
        setQuoteCalculate(false)
        if (orderObject) {
          setOrderObject({
            ...orderObject,
            order_fnI:
              orderObject?.finance_type === 'Finance'
                ? quotationParameterData?.finance_fni
                : quotationParameterData?.lease_fni
          })
        }
      }
    }, [quoteCalculate])
    useEffect(() => {
      if (states?.dealerAndLenderInfo?.dealer_code) {
        getPreference(
          { dealer_code: states?.dealerAndLenderInfo?.dealer_code },
          {
            onSuccess(dealerPreferenceData: any) {
              getAllowedMileage(
                { company_id: states?.dealerAndLenderInfo?.company_id },
                {
                  onSuccess(response: any) {
                    setMileageData(response)
                    const defaultAnnualMileage = response.filter((mileage: any) => {
                      return (
                        mileage.id === dealerPreferenceData?.default_mileage_id &&
                        mileage.is_active == true
                      )
                    })
                    setDefaultMileageValue(
                      defaultAnnualMileage.length > 0 ? defaultAnnualMileage[0].value : 0
                    )
                  }
                }
              )
            }
          }
        )
      }
      if (states?.dealerAndLenderInfo?.company_id) {
        getContractTerms({ company_id: states?.dealerAndLenderInfo?.company_id })
        getCreditRating({ company_id: states?.dealerAndLenderInfo?.company_id })
      }
    }, [states?.dealerAndLenderInfo?.company_id, states?.dealerAndLenderInfo?.dealer_code])
    useEffect(() => {
      if (states?.dealerProfileData?.dealer_address?.state_id) {
        const stateCode =
          stateData &&
          stateData?.find((x: any) => x?.id === states.dealerProfileData?.dealer_address?.state_id)
            ?.code
        getAllDealerFee({
          dealer_id: states?.dealerAndLenderInfo?.dealer_code,
          state_code: `${stateCode}`
        })
      }
    }, [states?.dealerProfileData?.dealer_address?.state_id])
    const { mutate: getAssetIdsByModelCode, data: assetIds } = useGetVehicleIdUsingModelCode()
    const {
      mutateAsync: getOrderCalculationRequest,
      isLoading: calcReqLoading,
      error: calcReqError,
      data: orderRequestData
    } = useOrderCalculationRequest()
    const assetData = assetIds
    useEffect(() => {
      if (vehicle?.model_code) {
        getAssetIdsByModelCode({ model_code: vehicle?.model_code })
      }
    }, [vehicle?.model_code])
    useEffect(() => {
      if (orderStage === 'Quotation') {
        if (
          assetData &&
          states?.orderData?.selected_vehicle &&
          states?.lenderInfo?.company_code &&
          contractTermsData &&
          creditRatingData &&
          allowedMileageData &&
          (allDealerFeeData ||
              (!orderId ||
              (orderId && mode === 'Edit' && quotationObject?.length > 0) ||
              orderObject))
      ) {
          const maxRatingData = states?.quotationDefaultParams?.defaultCreditRating
            ? creditRatingData?.filter(
              (rating: any) => rating.id == states?.quotationDefaultParams?.defaultCreditRating
            )[0]
            : creditRatingData?.reduce(
              (maxObj: any, currentObj: any) => {
                return currentObj.score_to > maxObj.score_to ? currentObj : maxObj
              },
              { id: -1, score_to: Number.NEGATIVE_INFINITY }
            )
          setMaxCreditRating((prevState: any) => {
            return { ...prevState, ...maxRatingData }
          })
          let credit_rating = orderObject?.credit_rating
            ? orderObject?.credit_rating
            : quotationObject[0]?.credit_rating
          getProgramInformationRequest(
            {
              lender_code: states?.dealerAndLenderInfo?.lender_code,
              company_id: states?.dealerAndLenderInfo?.company_id,
              company_code: states?.lenderInfo?.company_code,
              dealer_code: states?.dealerAndLenderInfo?.dealer_code,
              model_code: vehicle?.model_code,
              model_detail_name: vehicle?.trim_description,
              dealer_id: states?.dealerAndLenderInfo?.id,
              asset_make_id: assetData?.asset_make_id,
              asset_model_id: assetData?.asset_model_id,
              asset_model_detail_id: assetData?.asset_trim_id,
              credit_rating: credit_rating ? credit_rating : maxRatingData?.description,
              make_name: vehicle?.make,
              model_name: vehicle?.model,
              rental_frequency:
                ((quotationObject?.length > 0 && quotationObject[0]?.rental_frequency) ||
                  orderObject?.rental_frequency) ??
                DEFAULT_RENTAL_FREQUENCY,
              rental_mode: null,
              annual_usage:
                (quotationObject?.length > 0 && quotationObject[0]?.annual_usage) ||
                  orderObject?.annual_usage
                  ? orderObject?.annual_usage
                  : allowedMileageData?.filter(
                    (mileage: any) =>
                      mileage.id === states?.dealerPreferenceData?.default_mileage_id
                  )[0]?.value,
              terms:
                ((quotationObject?.length > 0 && quotationObject[0]?.contract_term) ||
                  orderObject?.contract_term) ??
                states?.quotationDefaultParams?.defaultTerms ??
                contractTermsData?.filter(
                  (term: any) => term.id == states?.dealerPreferenceData?.default_term_id
                )[0].term,
              retrieve_all_programs: false
            },
            {
              onSuccess(response: any) {
                setProgramResponse(response)
                if (response?.length > 0) {
                  const finance_program = response.filter(
                    (program: any) => program.finance_type == 'Finance'
                  )
                  const lease_program = response.filter(
                    (program: any) => program.finance_type == 'Lease'
                  )
                  const leaseQuotation = quotationObject?.filter(
                    (payload: any) => payload.finance_type == 'Lease'
                  )
                  const financeQuotation = quotationObject?.filter(
                    (payload: any) => payload.finance_type == 'Finance'
                  )
                  const allDealerLeaseFeeData = allDealerFeeData?.filter(
                    (fee: any) => fee.applicable_finance_type.includes('Lease') && fee.is_active
                  )[0]
                  const allDealerFinanceData = allDealerFeeData?.filter(
                    (fee: any) => fee.applicable_finance_type.includes('Finance') && fee.is_active
                  )[0]

                  response.map(async (program: any) => {
                    const calculationRequestObject = calculationRequest(program)
                    const quotationProgram =
                      quotationObject?.length > 0
                        ? quotationObject?.filter(
                          (payload: any) => payload.finance_type == program.finance_type
                        )
                          ? quotationObject?.filter(
                            (payload: any) => payload.finance_type == program.finance_type
                          )[0]
                          : undefined
                        : undefined
                    await getOrderCalculationRequest(calculationRequestObject).then(
                      (calculationResponse: any) => {
                        setCalculationPayload((prevState: any) => {
                          const clonedObjects = [...prevState]
                          const indexToReplace = clonedObjects.findIndex(
                            (obj) => obj.finance_type == program.finance_type
                          )
                          if (indexToReplace !== -1) {
                            clonedObjects[indexToReplace] = calculationRequestObject
                          } else {
                            clonedObjects.push(calculationRequestObject)
                          }
                          return clonedObjects
                        })
                        setProgramCalculationData((prevState: any) => {
                          const isProgramExist = prevState.some(
                            (_program: any) => _program.program.fp_id === program.fp_id
                          )
                          return [
                            ...prevState,
                            ...(!isProgramExist
                              ? [{ program: program, calculation: calculationResponse }]
                              : [])
                          ]
                        })
                        setQuotationParameterData({
                          capitalized_cost_reduction:
                            calculationResponse?.capitalized_cost_reduction ?? null,
                          rebate: calculationResponse?.rebate_promotions ?? null,
                          tax_amount_number: calculationResponse?.monthly_sales_use_tax ?? null,
                          tax_amount:
                            calculationResponse?.tax_amount == 0
                              ? calculationResponse?.tax_amount
                              : calculationResponse?.tax_amount,
                          reference_number: quotationProgram?.reference_number ?? null,
                          contract_term_id: contractTermsData?.filter(
                            (term: any) =>
                              term.term ===
                              (leaseQuotation?.length > 0
                                ? leaseQuotation[0]?.contract_term
                                : calculationRequestObject.terms)
                          )[0].id,
                          finance_rate:
                            financeQuotation?.length > 0
                              ? financeQuotation[0].apr
                              : finance_program?.length > 0
                                ? finance_program[0].final_customer_rate
                                  ? finance_program[0].final_customer_rate
                                  : 0
                                : 0,
                          lease_rate:
                            leaseQuotation?.length > 0
                              ? leaseQuotation[0].apr
                              : lease_program?.length > 0
                                ? lease_program[0].final_customer_rate
                                  ? lease_program[0].final_customer_rate
                                  : 0
                                : 0,
                          finance_down_payment:
                            financeQuotation?.length > 0
                              ? financeQuotation[0].down_payment
                              : finance_program?.length > 0
                                ? finance_program[0].downpayment_chart
                                  ? finance_program[0].downpayment_chart[0].default_down_payment
                                  : 0
                                : 0,
                          lease_down_payment:
                            leaseQuotation?.length > 0
                              ? leaseQuotation[0].down_payment
                              : lease_program?.length > 0
                                ? lease_program[0].downpayment_chart
                                  ? lease_program[0].downpayment_chart[0].default_down_payment
                                  : 0
                                : 0,
                          annual_mileage:
                            leaseQuotation?.length > 0
                              ? leaseQuotation[0]?.annual_usage
                              : defaultMileageValue ||
                              allowedMileageData?.filter(
                                (milage: any) =>
                                  milage?.id == states?.dealerPreferenceData?.default_mileage_id
                              )?.[0]?.value,
                          selling_price: calculationRequestObject.per_unit_selling_price,
                          credit_rating:
                            leaseQuotation?.length > 0
                              ? leaseQuotation[0]?.credit_rating
                              : calculationRequestObject.credit_rating,
                          rental_frequency: calculationRequestObject.rental_frequency,
                          options:
                            quotationProgram?.order_options ??
                            (states?.selectedOrderOptions ? states?.selectedOrderOptions : null),
                          fni_products:
                            //(quotationProgram?.order_fnI ?? calculationRequestObject.fni_products) ||
                            [],
                          insurance:
                            quotationProgram?.insurance ?? quotationParameterData?.insurance ?? null,
                          trade_in: quotationProgram?.order_tradein ?? null,
                          asset_usage: quotationProgram?.asset_usage ?? null,
                          schedule_option:
                            quotationProgram?.schedule_option ?? ScheduleOptions.PickUp,
                          finance_rv_rate:
                            financeQuotation?.length > 0
                              ? financeQuotation[0].rv_balloon_percentage
                              : finance_program?.length > 0
                                ? finance_program[0].rv_chart
                                  ? finance_program[0].rv_chart[0].rv_value
                                  : 0
                                : 0,
                          lease_rv_rate:
                            leaseQuotation?.length > 0
                              ? leaseQuotation[0].rv_balloon_percentage
                              : lease_program?.length > 0
                                ? lease_program[0].rv_chart
                                  ? lease_program[0].rv_chart[0].rv_value
                                  : 0
                                : 0,
                          lease_fees:
                            leaseQuotation[0]?.order_fees?.length > 0
                              ? (leaseQuotation[0]?.order_fees as any[])?.map((fee) => ({
                                ...fee,
                                fee_handling: allDealerLeaseFeeData?.fee_handling
                              }))
                              : (allDealerFeeData || []).filter((fee: any) =>
                                fee?.applicable_finance_type?.includes('Lease') && fee?.is_active
                              ).map((fee: any) => ({
                                fee_name: fee.fee_name,
                                dealer_fee_id: fee.id,
                                applied_price: fee.default_amount,
                                identifier: quotationProgram?.identifier ?? undefined,
                                fee_handling: fee?.fee_handling,
                                is_active: fee?.is_active,
                                applicable_finance_type: fee?.applicable_finance_type
                              })),
                          finance_fees:
                            financeQuotation[0]?.order_fees?.length > 0
                              ? (financeQuotation[0]?.order_fees as any[]).map((fee) => ({
                                ...fee,
                                fee_handling: allDealerFinanceData?.fee_handling
                              }))
                              : (allDealerFeeData || []).filter((fee: any) =>
                                fee?.applicable_finance_type?.includes('Finance') && fee?.is_active
                              ).map((fee: any) => ({
                                fee_name: fee?.fee_name,
                                dealer_fee_id: fee?.id,
                                applied_price: fee?.default_amount,
                                identifier: quotationProgram?.identifier ?? undefined,
                                fee_handling: fee?.fee_handling,
                                is_active: fee?.is_active,
                                applicable_finance_type: fee?.applicable_finance_type
                              })),
                          finance_fni:
                            financeQuotation?.length > 0
                              ? financeQuotation[0].order_fnI?.map((fni: any) => {
                                return {
                                  financial_insurance_id: fni?.financial_insurance_id,
                                  applied_price: fni?.applied_price,
                                  form_id: fni?.form_id,
                                  rate_id: fni?.rate_id,
                                  session_id: fni?.session_id,
                                  product_name: fni?.product_name,
                                  identifier: fni?.identifier
                                }
                              })
                              : [],
                          lease_fni:
                            leaseQuotation?.length > 0
                              ? leaseQuotation[0].order_fnI?.map((fni: any) => {
                                return {
                                  financial_insurance_id: fni?.financial_insurance_id,
                                  applied_price: fni?.applied_price,
                                  form_id: fni?.form_id,
                                  rate_id: fni?.rate_id,
                                  session_id: fni?.session_id,
                                  product_name: fni?.product_name,
                                  identifier: fni?.identifier
                                }
                              })
                              : []
                        })
                      }
                    )
                  })
                }
              }
            }
          )
        }
      } else {
        setProgramCalculationData([
          {
            program: {
              downpayment_chart: orderObject?.downpayment_chart,
              finance_type: orderObject?.finance_type,
              rental_mode: orderObject?.rental_mode,
              final_customer_rate: orderObject?.apr,
              fp_id: orderObject?.fp_id,
              calculation_method: orderObject?.calcuation_method,
              interest_chart: [{ margin: orderObject?.margin, ...orderObject?.interest_chart?.[0] }],
              base_rate_chart: { base_rate: orderObject?.base_rate }
            },
            calculation: {
              down_payment: (orderObject?.selling_price * orderObject?.down_payment) / 100,
              due_at_signing: orderObject?.due_at_signing,
              estimated_monthly_payment: orderObject?.estimated_monthly_payment,
              monthly_sales_use_tax: orderObject?.tax_amount_number,
              monthly_payment:
                orderObject?.estimated_monthly_payment + (orderObject?.tax_amount_number || 0),
              apr: orderObject?.apr,
              //expiry_date: "2023-09-06",
              //final_rate: 1.25,
              rv_amount: (orderObject?.selling_price * orderObject?.rv_balloon_percentage) / 100,
              selling_price: orderObject?.selling_price
            }
          }
        ])
        setQuotationParameterData({
          reference_number: orderObject?.reference_number,
          contract_term_id: contractTermsData?.filter(
            (term: any) => term.term === orderObject?.contract_term
          )[0].id,
          finance_rate: orderObject?.apr ? orderObject?.apr : 0,
          lease_rate: orderObject?.apr ? orderObject?.apr : 0,
          finance_down_payment: orderObject?.down_payment ? orderObject?.down_payment : 0,
          lease_down_payment: orderObject?.down_payment ? orderObject?.down_payment : 0,
          annual_mileage: orderObject?.annual_usage ?? defaultMileageValue ?? 0,
          selling_price: orderObject?.selling_price,
          credit_rating: orderObject?.credit_rating,
          rental_frequency: orderObject?.rental_frequency,
          options: orderObject?.order_options,
          lease_fni: orderObject?.finance_type == 'Lease' ? orderObject?.order_fnI : [],
          finance_fni: orderObject?.finance_type == 'Finance' ? orderObject?.order_fnI : [],
          insurance: orderObject?.Insurance_Inforation,
          trade_in: orderObject?.order_tradein,
          asset_usage: orderObject?.asset_usage,
          schedule_option: orderObject?.schedule_option,
          finance_rv_rate: orderObject?.rv_balloon_percentage,
          lease_rv_rate: orderObject?.rv_balloon_percentage,
          lease_fees: orderObject?.order_fees,
          finance_fees: orderObject?.order_fees,
          gross_capitalized_cost: orderObject?.finance_amount,
          adjusted_capitalized_cost: orderObject?.net_finance_amount,
          capitalized_cost_reduction: orderObject?.capitalized_cost_reduction,
          rebate: orderObject?.rebate,
          tax_amount: orderObject?.tax_amount
        })
      }
    }, [
      assetData,
      states?.orderData?.selected_vehicle,
      states?.lenderInfo,
      contractTermsData,
      creditRatingData,
      allDealerFeeData,
      mileageData,
      mode,
      orderObject
    ])

    useToastFunction(
      calcReqLoading || programReqLoading,
      orderRequestData || programData,
      calcReqError || programReqError,
      {
        text:
          calcReqLoading || programReqLoading
            ? 'Calculation in progress'
            : calcReqError || programReqError
              ? 'Some Error Orccured'
              : 'Successful',
        type:
          calcReqError || programReqError
            ? 'error'
            : calcReqLoading || programReqLoading
              ? 'info'
              : 'success'
      }
    )
    const calculationRequest = (program: any) => {
      const quotationProgram =
        quotationObject?.length > 0
          ? quotationObject?.filter((payload: any) => payload.finance_type == program.finance_type)
            ? quotationObject?.filter(
              (payload: any) => payload.finance_type == program.finance_type
            )[0]
            : undefined
          : undefined
      const calculationRequestObject: any = {}
      const maxRatingData = states?.quotationDefaultParams?.defaultCreditRating
        ? creditRatingData?.filter(
          (rating: any) => rating.id == states?.quotationDefaultParams?.defaultCreditRating
        )[0]
        : creditRatingData?.reduce(
          (maxObj: any, currentObj: any) => {
            return currentObj.score_to > maxObj.score_to ? currentObj : maxObj
          },
          { id: -1, score_to: Number.NEGATIVE_INFINITY }
        )
      const aprDenominator = 1
      const defaultTerms = contractTermsData?.filter(
        (term: any) => term.id == states?.dealerPreferenceData?.default_term_id
      )
        ; (calculationRequestObject.dealer_id = states?.dealerAndLenderInfo?.id),
          (calculationRequestObject.asset_condition = 63),
          (calculationRequestObject.credit_rating =
            (quotationProgram?.credit_rating || orderObject?.credit_rating) ??
            (maxRatingData?.description ? maxRatingData?.description : 0)),
          (calculationRequestObject.calculation_method =
            (quotationProgram?.calculation_method || orderObject?.calculation_method) ??
            program.calculation_method),
          (calculationRequestObject.apr =
            ((quotationProgram?.apr || orderObject?.apr) ?? program?.final_customer_rate) /
            (calculationRequestObject?.calculation_method == 'flat plus' ? 2400 : aprDenominator)),
          (calculationRequestObject.terms =
            (quotationProgram?.contract_term || orderObject?.contract_term) ??
            states?.quotationDefaultParams?.defaultTerms ??
            (defaultTerms?.length > 0 ? defaultTerms[0].term : '')),
          (calculationRequestObject.per_unit_selling_price =
            (quotationProgram?.selling_price || orderObject?.selling_price) ??
            states?.orderData?.selected_vehicle?.internet_price),
          (calculationRequestObject.down_payment =
            ((quotationProgram?.selling_price || orderObject?.selling_price || 0) *
              (quotationProgram?.down_payment || orderObject?.down_payment || 0)) /
              100 >
              0
              ? ((quotationProgram?.selling_price || orderObject?.selling_price || 0) *
                (quotationProgram?.down_payment || orderObject?.down_payment || 0)) /
              100
              : ((vehicle?.internet_price ? vehicle?.internet_price : 0) *
                (program?.downpayment_chart
                  ? program?.downpayment_chart[0].default_down_payment
                  : 0)) /
              100),
          (calculationRequestObject.taxes_amount = 0)
      calculationRequestObject.insurance_amount = 0
      calculationRequestObject.annual_usage =
        (quotationProgram?.annual_usage || orderObject?.annual_usage) ?? defaultMileageValue
      calculationRequestObject.rental_frequency =
        (quotationProgram?.rental_frequency || orderObject?.rental_frequency) ??
        DEFAULT_RENTAL_FREQUENCY
        ; (calculationRequestObject.down_payment_paid_by_dealer = 0),
          (calculationRequestObject.down_payment_paid_by_oem = 0)

        calculationRequestObject.fees = (
          (quotationProgram?.order_fees || orderObject?.order_fees)?.map((fee: any) => ({
            fee_name: fee.fee_name,
            fee_amount: fee.applied_price,
            fee_handling: fee?.fee_handling,
            is_active: fee?.is_active,
            applicable_finance_type: fee?.applicable_finance_type
          })) ||
          allDealerFeeData
            ?.filter(
              (fee: any) => fee.applicable_finance_type.includes(program.finance_type) && fee.is_active
            )
            ?.map((fee: any) => ({
              fee_name: fee.fee_name,
              fee_amount: fee.default_amount,
              fee_handling: fee?.fee_handling,
              is_active: fee?.is_active,
              applicable_finance_type: fee?.applicable_finance_type,
              is_taxable: fee?.taxable
            })) || []
        );
      (calculationRequestObject.fni_products =
        ((quotationProgram?.order_fnI || orderObject?.order_fnI) ??
          (program?.finance_type == 'Finance'
            ? quotationParameterData?.finance_fni
            : quotationParameterData?.lease_fni) ??
          []) ||
        []),
        (calculationRequestObject.trade_in_amount = quotationProgram?.order_tradein
          ? (quotationProgram?.order_tradein?.trade_in_amount || 0) -
          (quotationProgram?.order_tradein?.payoff_amount || 0)
          : quotationParameterData
            ? (quotationParameterData?.trade_in?.trade_in_amount || 0) -
            (quotationParameterData?.trade_in?.payoff_amount || 0)
            : (orderObject?.order_tradein?.trade_in_amount || 0) -
            (orderObject?.order_tradein?.payoff_amount || 0)),
        (calculationRequestObject.base_rate =
          (quotationProgram?.base_rate || orderObject?.base_rate) ??
          (program?.base_rate_chart?.base_rate || 0)),
        (calculationRequestObject.interest_chart_margin =
          (quotationProgram?.margin || orderObject?.margin) ?? program?.interest_chart
            ? program?.interest_chart[0].margin
            : 0),
        (calculationRequestObject.insurance = []),
        (calculationRequestObject.rental_mode = program.rental_mode),
        (calculationRequestObject.options =
          (quotationProgram?.order_options || orderObject?.order_options)
            ?.filter(
              (option: any) =>
                (option.installation_mode === 'Pre-Installed' && !option.is_price_inclusive) ||
                option.installation_mode === 'Optional'
            )

            .map((option: any) => ({
              option_name: option.product_name,
              option_amount: option.applied_price || option.price || 0,
              rv_amount_on_option: option.rv_balloon_value || option.rv_adder || 0,
              installation_mode: option.installation_mode,
              is_price_inclusive: option.is_price_inclusive
            })) ||
          (states?.selectedOrderOptions || [])
            .filter(
              (option: any) =>
                (option.installation_mode === 'Pre-Installed' && !option.is_price_inclusive) ||
                option.installation_mode === 'Optional'
            )
            .map((option: any) => ({
              option_name: option.product_name,
              option_amount: option.applied_price || option.price || 0,
              rv_amount_on_option: option.rv_balloon_value || option.rv_adder || 0,
              installation_mode: option.installation_mode,
              is_price_inclusive: option.is_price_inclusive
            })) ||
          []),
        (calculationRequestObject.net_financed_amount =
          calculationRequestObject.per_unit_selling_price -
          calculationRequestObject.down_payment +
          (calculationRequestObject.fees?.reduce(
            (total: number, currentItem: any) =>
              currentItem?.applicable_finance_type?.includes(program?.finance_type) &&
                currentItem?.fee_handling.toLowerCase() === FeeHandlingType.FINANCE
                ? total + currentItem.fee_amount
                : total,
            0
          ) || 0) +
          (calculationRequestObject?.fni_products?.reduce(
            (total: number, currentItem: any) => total + currentItem.applied_price,
            0
          ) || 0) +
          (calculationRequestObject.options?.reduce(
            (total: number, currentItem: any) =>
              total +
              ((currentItem?.installation_mode === InstallationMode.PRE_INSTALLED &&
                !currentItem?.is_price_inclusive) ||
                currentItem?.installation_mode !== InstallationMode.PRE_INSTALLED
                ? currentItem?.option_amount
                : 0),
            0
          ) || 0) -
          calculationRequestObject?.trade_in_amount),
        (calculationRequestObject.fni_products = []),
        (calculationRequestObject.rv_value =
          (calculationRequestObject.per_unit_selling_price *
            ((quotationProgram?.rv_balloon_percentage || orderObject?.rv_balloon_percentage) ??
              (program?.rv_chart
                ? program?.rv_chart[0]?.rv_value
                  ? program?.rv_chart[0]?.rv_value
                  : 0
                : 0))) /
          100 +
          (calculationRequestObject.options?.reduce(
            (total: number, currentItem: any) =>
              total +
              ((currentItem.installation_mode === InstallationMode.PRE_INSTALLED &&
                !currentItem?.is_price_inclusive) ||
                currentItem?.installation_mode !== InstallationMode.PRE_INSTALLED
                ? currentItem?.rv_amount_on_option || 0
                : 0),
            0
          ) || 0)),
        (calculationRequestObject.rv_amount = 0),
        (calculationRequestObject.structure_rentals = []),
        (calculationRequestObject.subsidy = {
          subsidy_calculation_method: 'percentage from Dealer and percentage from Manufacturer',

          financier_rate: 0,

          manufacturer_subsidy_rate: 2,

          dealer_subsidy_rate: 1
        }),
        // as per discussion with PO
        (calculationRequestObject.trade_ins =
          (quotationParameterData?.trade_in && [
            {
              vin: quotationParameterData?.trade_in?.vin,
              offer_amount: quotationParameterData?.trade_in?.trade_in_amount,
              pay_off_amount: quotationParameterData?.trade_in?.payoff_amount,
              allowance_amount: quotationParameterData?.trade_in?.trade_in_amount
            }
          ]) ||
          []),
        (calculationRequestObject.quantity = 1),
        (calculationRequestObject.is_rv_guaranteed = 'False'),
        (calculationRequestObject.taxes = []),
        (calculationRequestObject.finance_type =
          (quotationProgram?.finance_type || orderObject?.finance_type) ?? program.finance_type),
        (calculationRequestObject.markup_rate = 0),
        // For new customer we will pass customer address.
        (calculationRequestObject.customer_address = {
          street_address:
            mailingAddresses[0]?.address_line_1 ?? dealerProfile?.dealer_address?.address_line_1,
          city: mailingAddresses[0]?.city ?? dealerProfile?.dealer_address?.city,
          state: mailingAddresses[0]?.state_name ?? dealerProfile?.dealer_address?.state_name,
          zip_code: mailingAddresses[0]?.zip_code ?? dealerProfile?.dealer_address?.zip_code
        }),
        (calculationRequestObject.dealer_address = {
          street_address: dealerProfile?.dealer_address?.address_line_1,
          city: dealerProfile?.dealer_address?.city,
          state: dealerProfile?.dealer_address?.state_name,
          zip_code: dealerProfile?.dealer_address?.zip_code
        }),
        (calculationRequestObject.vehicle = {
          vin: vehicle?.vin,
          is_new: true,
          odometer_mileage: vehicle?.mileage,
          msrp: vehicle?.msrp,
          year: vehicle?.year,
          transmission_type: vehicle?.transmission_type
        })
      return calculationRequestObject
    }

    const reCalculateQuote = (evaluateProgram = true) => {
      if (orderStatus == OrderState.Draft && evaluateProgram) {
        getProgramInformationRequest(
          {
            lender_code: states?.dealerAndLenderInfo?.lender_code,
            company_id: states?.dealerAndLenderInfo?.company_id,
            company_code: states?.lenderInfo?.company_code,
            dealer_code: states?.dealerAndLenderInfo?.dealer_code,
            model_code: vehicle?.model_code,
            model_detail_name: vehicle?.trim_description,
            dealer_id: states?.dealerAndLenderInfo?.id,
            asset_make_id: assetData?.asset_make_id,
            asset_model_id: assetData?.asset_model_id,
            asset_model_detail_id: assetData?.asset_trim_id,
            credit_rating: quotationParameterData?.credit_rating,
            make_name: vehicle?.make,
            model_name: vehicle?.model,
            rental_frequency: quotationParameterData?.rental_frequency,
            rental_mode: null,
            annual_usage: quotationParameterData?.annual_mileage,
            terms: contractTermsData?.filter(
              (term: any) => term.id == quotationParameterData?.contract_term_id
            )[0].term,
            retrieve_all_programs: false
          },
          {
            onSuccess(response: any) {
              setProgramResponse(response)
              const programCalculationState: any[] = []
              response?.map(async (program: any) => {
                const newState = programCalculationData.filter(
                  (newData: any) => newData?.program?.finance_type === program?.finance_type
                )
                programCalculationState.push({
                  program: program,
                  calculation: newState?.[0]?.calculation
                })
              })
              setProgramCalculationData(() => {
                return programCalculationState
              })

              let financeProgram: any
              let leaseProgram: any
              programCalculationState?.map((data: any) => {
                if (data.program.finance_type == 'Finance') {
                  financeProgram = data.program
                } else {
                  leaseProgram = data.program
                }
              })
              setQuotationParameterData({
                ...quotationParameterData,
                finance_rate: financeProgram?.final_customer_rate ?? 0,
                lease_rate: leaseProgram?.final_customer_rate ?? 0,
                finance_rv_rate: financeProgram?.rv_chart
                  ? financeProgram?.rv_chart[0]?.rv_value || 0
                  : 0,
                lease_rv_rate: leaseProgram?.rv_chart ? leaseProgram?.rv_chart[0]?.rv_value || 0 : 0
              })
              recalculateProgramData(programCalculationState)
            }
          }
        )
      } else {
        recalculateProgramData()
      }
    }

    const recalculateProgramData = (programCalculationState: any[] = []) => {
      const programCalculation =
        programCalculationState.length > 0 ? programCalculationState : programCalculationData
      const requestObj = programCalculation?.map((data: any) => {
        const calculationRequestObject = calculationRequest(data?.program)
        calculationRequestObject.options =
          (quotationParameterData?.options || quotationParameterData?.options)
            ?.filter(
              (option: any) =>
                (option.installation_mode === 'Pre-Installed' && !option.is_price_inclusive) ||
                option.installation_mode === 'Optional'
            )
            .map((option: any) => {
              return {
                option_name: option.product_name,
                option_amount: option.applied_price || option.price || 0,
                rv_amount_on_option: option.rv_balloon_value || option.rv_adder,
                installation_mode: option?.installation_mode,
                is_price_inclusive: option?.is_price_inclusive
              }
            }) || []
        calculationRequestObject.per_unit_selling_price = quotationParameterData.selling_price
        calculationRequestObject.down_payment =
          programCalculationState?.length > 0
            ? (calculationRequestObject.per_unit_selling_price *
              (data?.program?.downpayment_chart
                ? data?.program?.downpayment_chart[0]?.default_down_payment
                : 0)) /
            100
            : (calculationRequestObject.per_unit_selling_price *
              (data?.program.finance_type === 'Finance'
                ? quotationParameterData.finance_down_payment
                : quotationParameterData.lease_down_payment || 0)) /
            100
        calculationRequestObject.rv_value =
          (programCalculationState?.length > 0
            ? data?.program?.rv_chart
              ? (quotationParameterData?.selling_price * data?.program?.rv_chart[0]?.rv_value) / 100
              : 0
            : data?.program.finance_type === 'Finance'
              ? (quotationParameterData?.selling_price * quotationParameterData.finance_rv_rate) /
              100 || 0
              : (quotationParameterData?.selling_price * quotationParameterData.lease_rv_rate) / 100 ||
              0) +
          (calculationRequestObject.options?.reduce(
            (total: number, currentItem: any) =>
              total +
              ((currentItem.installation_mode === InstallationMode.PRE_INSTALLED &&
                !currentItem.is_price_inclusive) ||
                currentItem.installation_mode !== InstallationMode.PRE_INSTALLED
                ? currentItem.rv_amount_on_option || 0
                : 0),
            0
          ) || 0)
        calculationRequestObject.fees =
          data?.program.finance_type === 'Finance'
            ? quotationParameterData.finance_fees?.map((fee: any) => {
              return {
                fee_name: fee.fee_name,
                fee_amount: fee.applied_price,
                fee_handling: fee?.fee_handling,
                is_active: fee?.is_active,
                applicable_finance_type: fee?.applicable_finance_type,
                is_taxable: fee?.taxable
              }
            }) || []
            : quotationParameterData.lease_fees?.map((fee: any) => {
              return {
                fee_name: fee.fee_name,
                fee_amount: fee.applied_price,
                fee_handling: fee?.fee_handling,
                is_active: fee?.is_active,
                applicable_finance_type: fee?.applicable_finance_type,
                is_taxable: fee?.taxable
              }
            }) || []

        calculationRequestObject.fni_products =
          (data?.program?.finance_type == 'Finance'
            ? quotationParameterData?.finance_fni
            : quotationParameterData?.lease_fni) || []
        calculationRequestObject.apr =
          data?.program?.finance_type == 'Finance'
            ? quotationParameterData?.finance_rate
            : quotationParameterData?.lease_rate
        calculationRequestObject.rental_frequency = quotationParameterData?.rental_frequency
          ; (calculationRequestObject.terms = contractTermsData?.filter(
            (term: any) => term.id === quotationParameterData?.contract_term_id
          )[0].term),
            (calculationRequestObject.annual_usage = quotationParameterData?.annual_mileage)
        calculationRequestObject.credit_rating = quotationParameterData?.credit_rating
        calculationRequestObject.finance_type = data?.program.finance_type
        calculationRequestObject.per_unit_selling_price = quotationParameterData.selling_price
        calculationRequestObject.net_financed_amount =
          calculationRequestObject.per_unit_selling_price -
          calculationRequestObject.down_payment +
          (calculationRequestObject.fees?.reduce(
            (total: number, currentItem: any) =>
              currentItem?.applicable_finance_type?.includes(data?.program?.finance_type) &&
                currentItem?.fee_handling.toLowerCase() === FeeHandlingType.FINANCE
                ? total + currentItem.fee_amount
                : total,
            0
          ) || 0) +
          (calculationRequestObject?.fees?.reduce(
            (total: number, currentItem: any) =>
              currentItem?.applicable_finance_type?.includes(data?.program?.finance_type) &&
                currentItem?.fee_handling.toLowerCase() === FeeHandlingType.FINANCE
                ? total + currentItem.applied_price
                : total,
            0
          ) || 0) +
          (calculationRequestObject.options?.reduce(
            (total: number, currentItem: any) =>
              total +
              ((currentItem.installation_mode === InstallationMode.PRE_INSTALLED &&
                !currentItem.is_price_inclusive) ||
                currentItem.installation_mode !== InstallationMode.PRE_INSTALLED
                ? currentItem.option_amount
                : 0),
            0
          ) || 0) +
          (calculationRequestObject.fni_products?.reduce(
            (total: number, currentItem: any) => total + currentItem.applied_price,
            0
          ) || 0) -
          calculationRequestObject.trade_in_amount
        calculationRequestObject.fni_products = []
        return calculationRequestObject
      })

      requestObj?.map(async (calculationRequestObject: any) => {
        await getOrderCalculationRequest(calculationRequestObject).then(
          (calculationResponse: any) => {
            setCalculationPayload((prevState: any) => {
              const clonedObjects = [...prevState]
              const indexToReplace = clonedObjects.findIndex(
                (obj) => obj.finance_type === calculationRequestObject?.finance_type
              )
              if (indexToReplace !== -1) {
                clonedObjects[indexToReplace] = calculationRequestObject
              } else {
                clonedObjects.push(calculationRequestObject)
              }
              return clonedObjects
            })
            setProgramCalculationData((prevState: any) => {
              const updatedData = prevState.map((existingData: any) => {
                if (existingData.program.finance_type === calculationRequestObject.finance_type) {
                  return { program: existingData.program, calculation: calculationResponse }
                }
                return existingData
              })
              return updatedData
            })
            setQuotationParameterData({
              ...quotationParameterData,
              tax_amount: calculationResponse?.tax_amount ?? 0,
              capitalized_cost_reduction: calculationResponse?.capitalized_cost_reduction ?? 0,
              gross_capitalized_cost: calculationResponse?.gross_capitalized_cost ?? 0,
              adjusted_capitalized_cost: calculationResponse?.adjusted_capitalized_cost
            })
          }
        )
      })
    }
    const discardChanges = () => {
      const financeProgram = programCalculationData.filter(
        (data: any) => data.program.finance_type == 'Finance'
      )
      const leaseProgram = programCalculationData.filter(
        (data: any) => data.program.finance_type == 'Lease'
      )
      const leaseQuotation = quotationObject?.filter(
        (payload: any) => payload.finance_type == 'Lease'
      )
      const financeQuotation = quotationObject?.filter(
        (payload: any) => payload.finance_type == 'Finance'
      )
      const maxRatingData = states?.quotationDefaultParams?.defaultCreditRating
        ? creditRatingData?.filter(
          (rating: any) => rating.description == states?.quotationDefaultParams?.defaultCreditRating
        )[0]
        : creditRatingData?.reduce(
          (maxObj: any, currentObj: any) => {
            return currentObj.score_to > maxObj.score_to ? currentObj : maxObj
          },
          { id: -1, score_to: Number.NEGATIVE_INFINITY }
        )
      setQuotationParameterData({
        reference_number:
          (orderObject?.reference_number ||
            (quotationObject?.length > 0 && quotationObject[0].reference_number)) ??
          null,
        contract_term_id:
          !orderObject && !(quotationObject?.length > 0)
            ? states?.quotationDefaultParams?.defaultTerms
              ? contractTermsData?.filter(
                (term: any) => term.term === states?.quotationDefaultParams?.defaultTerms
              )[0]?.id
              : states?.dealerPreferenceData?.default_term_id
            : contractTermsData?.filter(
              (term: any) =>
                term.term ===
                (orderObject?.contract_term ||
                  (quotationObject?.length > 0 && quotationObject[0].contract_term))
            )[0]?.id,
        finance_down_payment:
          orderObject?.down_payment ??
          (financeQuotation?.length > 0
            ? financeQuotation[0].down_payment
            : financeProgram?.length > 0
              ? financeProgram[0].program.downpayment_chart
                ? financeProgram[0].program.downpayment_chart[0].default_down_payment
                : 0
              : 0),
        lease_down_payment:
          orderObject?.down_payment ??
          (leaseQuotation?.length > 0
            ? leaseQuotation[0].down_payment
            : leaseProgram?.length > 0
              ? leaseProgram[0].program.downpayment_chart
                ? leaseProgram[0].program.downpayment_chart[0].default_down_payment
                : 0
              : 0),
        annual_mileage:
          leaseQuotation?.length > 0 ? leaseQuotation[0]?.annual_usage : defaultMileageValue ?? 0,
        allowed_usage:
          leaseQuotation?.length > 0 ? leaseQuotation[0]?.annual_usage : defaultMileageValue ?? 0,
        annual_usage:
          leaseQuotation?.length > 0 ? leaseQuotation[0]?.annual_usage : defaultMileageValue ?? 0,
        selling_price:
          ((quotationObject?.length > 0 && quotationObject[0].selling_price) ||
            orderObject?.selling_price) ??
          states?.orderData?.selected_vehicle?.internet_price,
        credit_rating:
          ((quotationObject?.length > 0 && quotationObject?.credit_rating) ||
            orderObject?.credit_rating) ??
          (maxRatingData?.id ? maxRatingData?.id : 0),
        rental_frequency:
          ((quotationObject?.length > 0 && quotationObject[0].rental_frequency) ||
            orderObject?.rental_frequency) ??
          DEFAULT_RENTAL_FREQUENCY,
        options:
          ((quotationObject?.length > 0 && quotationObject[0].order_options) ||
            orderObject?.order_options) ??
          states?.selectedOrderOptions,
        insurance:
          ((quotationObject?.length > 0 && quotationObject[0].insurance) ||
            orderObject?.Insurance_Inforation) ??
          null,
        trade_in:
          ((quotationObject?.length > 0 && quotationObject[0].order_tradein) ||
            orderObject?.order_tradein) ??
          null,
        asset_usage:
          ((quotationObject?.length > 0 && quotationObject[0].asset_usage) ||
            orderObject?.asset_usage) ??
          null,
        schedule_option:
          ((quotationObject?.length > 0 && quotationObject[0].schedule_option) ||
            orderObject?.schedule_option) ??
          ScheduleOptions.PickUp,
        finance_rate:
          financeQuotation?.length > 0
            ? financeQuotation[0].apr
            : financeProgram?.length > 0
              ? financeProgram[0].program.final_customer_rate
                ? financeProgram[0].program.final_customer_rate
                : 0
              : 0,
        lease_rate:
          leaseQuotation?.length > 0
            ? leaseQuotation[0].apr
            : leaseProgram?.length > 0
              ? leaseProgram[0].program.final_customer_rate
                ? leaseProgram[0].program.final_customer_rate
                : 0
              : 0,
        finance_rv_rate:
          orderObject?.rv_rate ??
          (financeQuotation?.length > 0
            ? financeQuotation[0].rv_balloon_percentage
            : financeProgram?.length > 0
              ? financeProgram[0].program.rv_chart
                ? financeProgram[0].program.rv_chart[0].rv_value
                : 0
              : 0),
        lease_rv_rate:
          orderObject?.rv_rate ??
          (leaseQuotation?.length > 0
            ? leaseQuotation[0].rv_balloon_percentage
            : leaseProgram?.length > 0
              ? leaseProgram[0].program.rv_chart
                ? leaseProgram[0].program.rv_chart[0].rv_value
                : 0
              : 0),
        finance_fni:
          orderObject?.order_fnI ??
          (financeQuotation?.length > 0
            ? financeQuotation[0].order_fnI?.map((fni: any) => {
              return {
                financial_insurance_id: fni?.financial_insurance_id,
                applied_price: fni?.applied_price,
                product_name: fni?.product_name,
                form_id: fni?.form_id,
                rate_id: fni?.rate_id,
                session_id: fni?.session_id,
                identifier: fni?.identifier
              }
            })
            : []),
        lease_fni:
          orderObject?.order_fnI ??
          (leaseQuotation?.length > 0
            ? leaseQuotation[0].order_fnI?.map((fni: any) => {
              return {
                financial_insurance_id: fni?.financial_insurance_id,
                applied_price: fni?.applied_price,
                product_name: fni?.product_name,
                form_id: fni?.form_id,
                rate_id: fni?.rate_id,
                session_id: fni?.session_id,
                identifier: fni?.identifier
              }
            })
            : []),
        lease_fees:
          orderObject?.order_fees ??
          (leaseQuotation?.length > 0
            ? leaseQuotation[0].order_fees
            : [
              ...allDealerFeeData?.filter((fee: any) =>
                fee.applicable_finance_type.includes('Lease') && fee.is_active ? fee : null
              )
            ].map((fee: any) => {
              return {
                fee_name: fee.fee_name,
                dealer_fee_id: fee.id,
                applied_price: fee.default_amount,
                identifier:
                  (quotationObject?.length > 0 && quotationObject[0].identifier) ?? undefined,
                fee_handling: fee?.fee_handling,
                is_active: fee?.is_active,
                applicable_finance_type: fee?.applicable_finance_type
              }
            })),
        finance_fees:
          orderObject?.order_fees ??
          (financeQuotation?.length > 0
            ? financeQuotation[0].order_fees
            : [
              ...allDealerFeeData?.filter((fee: any) =>
                fee.applicable_finance_type.includes('Finance') && fee.is_active ? fee : null
              )
            ].map((fee: any) => {
              return {
                fee_name: fee.fee_name,
                dealer_fee_id: fee.id,
                applied_price: fee.default_amount,
                identifier:
                  (quotationObject?.length > 0 && quotationObject[0].identifier) ?? undefined,
                fee_handling: fee?.fee_handling,
                is_active: fee?.is_active,
                applicable_finance_type: fee?.applicable_finance_type
              }
            }))
      })
    }
    return (
      <>
        {programData?.length == 0 ? (
          <NotQuotation></NotQuotation>
        ) : (
          <Box sx={{ mb: 5 }}>
            {!calcReqLoading && (orderObject ? true : programCalculationData?.length > 0) && (
              <OrderQuotationComparison
                setPopUpState={setPopUpState}
                orderId={orderId}
                sendSaveOrderRequest={sendSaveOrderRequest}
                orderStatus={orderStatus}
                programCalculationData={
                  !calcReqLoading && !programReqLoading && programCalculationData
                }
                allowedMileageData={allowedMileageData}
                quotationParameterData={quotationParameterData}
                setQuotationParameterData={setQuotationParameterData}
                quotationObject={quotationObject}
                setOrderStage={setOrderStage}
                reCalculateQuote={reCalculateQuote}
                discardChanges={discardChanges}
              />
            )}
            {orderRequestData?.length <= 0 && !calcReqLoading && <NoCalculationData />}
          </Box>
        )}
      </>
    )
  }
export default CreateOrderQuotation
