import * as React from 'react'
import { styled } from '@mui/material/styles'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { ISwitchProps } from './Switch.types'

const lightImg = `url('data:image/svg+xml;utf8,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.0003 13.3333C11.8413 13.3333 13.3337 11.8409 13.3337 9.99996C13.3337 8.15901 11.8413 6.66663 10.0003 6.66663C8.15938 6.66663 6.66699 8.15901 6.66699 9.99996C6.66699 11.8409 8.15938 13.3333 10.0003 13.3333Z" stroke="${encodeURIComponent(
  '#12131A'
)}" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/><path d="M5.25 14.75L4.66667 15.3333M2.5 10H3.33333H2.5ZM10 2.5V3.33333V2.5ZM16.6667 10H17.5H16.6667ZM10 16.6667V17.5V16.6667ZM4.66667 4.66667L5.25 5.25L4.66667 4.66667ZM15.3333 4.66667L14.75 5.25L15.3333 4.66667ZM14.75 14.75L15.3333 15.3333L14.75 14.75Z" stroke="${encodeURIComponent(
  '#12131A'
)}" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/></svg>')`
const darkImg = `url('data:image/svg+xml;utf8,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.0001 2.49995C10.1101 2.49995 10.2193 2.49995 10.3276 2.49995C9.25709 3.49471 8.56897 4.83316 8.38288 6.28264C8.1968 7.73212 8.52453 9.20098 9.30909 10.4339C10.0937 11.6668 11.2855 12.5858 12.6773 13.0312C14.0692 13.4765 15.5731 13.4201 16.9276 12.8716C16.4066 14.1254 15.555 15.2141 14.4636 16.0218C13.3723 16.8295 12.0822 17.3258 10.7309 17.4578C9.37962 17.5897 8.01786 17.3524 6.79088 16.7711C5.56391 16.1898 4.51774 15.2864 3.76397 14.1571C3.0102 13.0279 2.5771 11.7152 2.51088 10.3591C2.44466 9.003 2.7478 7.65437 3.38795 6.45705C4.02811 5.25973 4.98128 4.25864 6.14578 3.56056C7.31028 2.86249 8.64244 2.49361 10.0001 2.49329V2.49995Z" stroke="${encodeURIComponent(
  '#fff'
)}" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/></svg>')`

const lightImgBefore = `url('data:image/svg+xml;utf8,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.0003 13.3333C11.8413 13.3333 13.3337 11.8409 13.3337 9.99996C13.3337 8.15901 11.8413 6.66663 10.0003 6.66663C8.15938 6.66663 6.66699 8.15901 6.66699 9.99996C6.66699 11.8409 8.15938 13.3333 10.0003 13.3333Z" stroke="${encodeURIComponent(
  '#696C80'
)}" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/><path d="M5.25 14.75L4.66667 15.3333M2.5 10H3.33333H2.5ZM10 2.5V3.33333V2.5ZM16.6667 10H17.5H16.6667ZM10 16.6667V17.5V16.6667ZM4.66667 4.66667L5.25 5.25L4.66667 4.66667ZM15.3333 4.66667L14.75 5.25L15.3333 4.66667ZM14.75 14.75L15.3333 15.3333L14.75 14.75Z" stroke="${encodeURIComponent(
  '#696C80'
)}" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/></svg>')`
const darkImgBefore = `url('data:image/svg+xml;utf8,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.0001 2.49995C10.1101 2.49995 10.2193 2.49995 10.3276 2.49995C9.25709 3.49471 8.56897 4.83316 8.38288 6.28264C8.1968 7.73212 8.52453 9.20098 9.30909 10.4339C10.0937 11.6668 11.2855 12.5858 12.6773 13.0312C14.0692 13.4765 15.5731 13.4201 16.9276 12.8716C16.4066 14.1254 15.555 15.2141 14.4636 16.0218C13.3723 16.8295 12.0822 17.3258 10.7309 17.4578C9.37962 17.5897 8.01786 17.3524 6.79088 16.7711C5.56391 16.1898 4.51774 15.2864 3.76397 14.1571C3.0102 13.0279 2.5771 11.7152 2.51088 10.3591C2.44466 9.003 2.7478 7.65437 3.38795 6.45705C4.02811 5.25973 4.98128 4.25864 6.14578 3.56056C7.31028 2.86249 8.64244 2.49361 10.0001 2.49329V2.49995Z" stroke="${encodeURIComponent(
  '#9A9EB2'
)}" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/></svg>')`

const MaterialUISwitch = styled(Switch)<ISwitchProps>(({ theme }) => ({
  '&.u-switch-mode': {
    width: 92,
    height: 40,
    padding: 0,
    margin: 0,
    '.MuiSwitch-track': {
      opacity: 1,
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      borderRadius: 24,
      '&::before': {
        display: 'block',
        content: "''",
        position: 'absolute',
        width: 20,
        height: 20,
        top: '50%',
        left: 12,
        right: 'auto',
        transform: 'translateY(-50%)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: lightImgBefore,
        backgroundSize: 20
      },
      '&::after': {
        display: 'block',
        content: "''",
        position: 'absolute',
        width: 20,
        height: 20,
        right: 12,
        left: 'auto',
        top: '50%',
        transform: 'translateY(-50%)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: darkImgBefore,
        backgroundSize: 20
      }
    },
    '.MuiSwitch-switchBase': {
      margin: 2,
      padding: 0,
      transform: 'translateX(1px)',
      '&.Mui-checked': {
        transform: 'translateX(44px)',
        '.MuiSwitch-thumb': {
          backgroundColor: theme.palette.grey[800]
        },
        '.MuiSwitch-thumb:before': {
          backgroundImage: darkImg
        },
        '+ .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    },
    '.MuiSwitch-thumb': {
      backgroundColor: theme.palette.common.white,
      width: 44,
      height: 36,
      borderRadius: 20,
      boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.16)',
      '&:before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: lightImg,
        backgroundSize: 20
      }
    },
    '.MuiTouchRipple-root': {
      display: 'none'
    }
  }
}))

const BasicSwitch = styled(Switch)<ISwitchProps>(({ theme }) => ({
  width: 40,
  height: 24,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      backgroundColor: theme.palette.grey[300]
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 20,
    height: 20,
    backgroundColor: theme.palette.common.white
  },
  '& .MuiSwitch-track': {
    borderRadius: 16,
    backgroundColor: theme.palette.grey[300],
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  },
  '+': {
    '.MuiFormControlLabel-label': {
      marginLeft: 8,
      fontSize: theme.typography.body2.fontSize,
      lineHeight: theme.typography.body2.lineHeight,
      fontWeight: theme.typography.fontWeightRegular,
      color: theme.palette.grey[600]
    }
  }
}))

const CustomizedSwitches: React.FC<ISwitchProps> = ({
  theme,
  onChange: onChangePorps,
  // value: defaultValue,
  label,
  varient,
  forControlEnabled,
  switchEnabled
}) => {
  const [isEnabled, setValue] = React.useState(switchEnabled)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.checked)
    onChangePorps?.(e, e.target.checked)
  }
  return (
    <FormGroup>
      <FormControlLabel
        className="u-switch-label"
        checked={forControlEnabled}
        sx={{ margin: 0 }}
        control={
          varient === 'basic' ? (
            <BasicSwitch theme={theme} onChange={onChange} checked={switchEnabled}></BasicSwitch>
          ) : (
            <MaterialUISwitch
              onChange={onChange}
              checked={isEnabled}
              theme={theme}
              className="u-switch-mode"
              sx={{ m: 1 }}
            >
              {' '}
            </MaterialUISwitch>
          )
        }
        label={label}
      />
    </FormGroup>
  )
}

export default CustomizedSwitches
