import { FC } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { styled, useTheme } from '@mui/material'
import { Typography, Box, Icon, IBoxProps, Button } from '@ntpkunity/controls'

const NotFoundWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({}) => ({
  '&.result-not-found': {
    textAlign: 'center',
    marginTop: '5%'
  },
  '@media screen and (max-width: 525px)': {
    '&.result-not-found': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 'calc(100vh - 70px)',
      svg: {
        width: '100%',
        height: 'auto'
      }
    }
  }
}))

const NotQuotation: FC = () => {
  const theme = useTheme()
  const { customer_reference_id } = useParams()
  let navigate = useNavigate()
  const onStepClick = () => {
    localStorage.setItem('step', 'GoToStep1')
    navigate('/dms/create-order/' + customer_reference_id)
  }
  return (
    <>
      <NotFoundWrap theme={theme} className="result-not-found">
        <Box theme={theme}>
          <Icon name="NoQuoteIcon" />
          <Typography theme={theme} variant="h3" component="h3" sx={{ mt: 4, mb: 1 }}>
            Looks like there is no quote added here!
          </Typography>
          <Typography theme={theme} variant="body2" component="p">
            The asset you are looking for does not have any quotation added to it.
          </Typography>
          <Typography theme={theme} variant="body2" component="p" mb={4}>
            Please look for some other asset to proceed with the order.
          </Typography>
        </Box>
        <Box theme={theme} sx={{ mb: 4 }}>
          <Button
            theme={theme}
            primary
            text={'Select Another Asset'}
            onClick={() => {
              onStepClick()
            }}
          />
        </Box>
      </NotFoundWrap>
    </>
  )
}

export default NotQuotation
