export const setFavicon = (
  icon = 'https://unitydmsbucket.s3.us-east-1.amazonaws.com/images/dealex.svg'
) => {
  let favicon = document.querySelector("link[rel~='icon']") as any
  if (!favicon) {
    favicon = document.createElement('link')
    favicon.rel = 'icon'
    document.getElementsByTagName('head')[0].appendChild(favicon)
  }
  favicon.href = icon
}

export const setStyleSheet = (stylesheet = 'theme-builder.css') => {
  let style = document.querySelector("link[rel~='stylesheet']") as any
  if (!style) {
    style = document.createElement('link')
    style.rel = 'stylesheet'
    style.type = 'text/css'
    style.target = '_blank'
    document.getElementsByTagName('head')[0].appendChild(style)
    style.href = stylesheet
  }
  style.href = stylesheet
}

export const setLogo = (icon = 'src/public/assets/images/BMW-Logo.svg') => {
  let logo = document.getElementById('unity_logo') as any
  logo.src = icon
}
