import React from 'react'
import { FC, useEffect, useState } from 'react'
import { Link, useTheme } from '@mui/material'
import { DetailCard } from 'components'
import { Typography, Grid, Box, Icon } from '@ntpkunity/controls'
import { ScheduleOptions, Status } from '@helpers/enums'
import RescheduleAppointmentDialog from './schedule-appointment-dialog'
import { useGetDealerProfileById } from '@apis/dealer-configurations.service'
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { useStoreContext } from '@store/storeContext'
import { formatTime } from 'helpers/methods'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)
dayjs.extend(localizedFormat)
const PickupAppointment: FC<{ order_info: any }> = ({ order_info }) => {
  const theme = useTheme()
  const [dateTime, setDateTime] = React.useState<Dayjs | null>()
  const { states } = useStoreContext()
  const { mutate: dealerProfile, data: dealer_profile_data } = useGetDealerProfileById()
  const [openPopup, setOpenPopup] = useState<boolean>(false)
  useEffect(() => {
    if (order_info?.appointment_details?.appointment_date) {
      const appointmentDetails = order_info?.appointment_details
      const { time_zone: timeZone, appointment_date: appointmentDate } = appointmentDetails || {}
      let adjustedAppointmentDate
      if (timeZone) {
        const isTimestamp = typeof appointmentDate === 'number'
        adjustedAppointmentDate = isTimestamp
          ? dayjs(appointmentDate * 1000).toISOString()
          : dayjs(appointmentDate)
        const dateTime = dayjs(adjustedAppointmentDate)
          .tz(dealer_profile_data?.time_zone_difference)
          .format('YYYY-MM-DD h:mm:ss A')
        setDateTime(dayjs(dateTime, 'YYYY-MM-DD h:mm:ss A'))
      } else {
        const formattedDate = formatTime(appointmentDate)
        setDateTime(dayjs(formattedDate, 'YYYY-MM-DD h:mm:ss A'))
      }
    }
  }, [order_info?.appointment_details, dealer_profile_data])

  function getTimePeriod() {
    const parsedTime = dayjs(dateTime, 'h:mm A')
    const hour = parsedTime.hour()
    if (hour >= 0 && hour < 12) {
      return 'Morning'
    } else if (hour >= 12 && hour < 18) {
      return 'Afternoon'
    } else {
      return 'Evening'
    }
  }
  useEffect(() => {
    if (states?.dealerInfo?.dealer_code) {
      dealerProfileData()
    }
  }, [states?.dealerInfo?.dealer_code])

  const dealerProfileData = () => {
    dealerProfile({ dealer_id: states?.dealerInfo?.dealer_code })
  }
  let dealerAddressDetails = [
    dealer_profile_data?.dealer_address?.address_line_1,
    dealer_profile_data?.dealer_address?.address_line_2,
    dealer_profile_data?.dealer_address?.city,
    dealer_profile_data?.dealer_address?.state_name
  ]
    .filter(Boolean)
    .join(', ')
  return (
    <>
      {order_info?.status == Status.Appointment ||
      order_info?.status == Status.SchedulePickup ||
      order_info?.status == Status.Completed ? (
        <DetailCard className="detail-card" mb={2}>
          <Typography theme={theme} component="h4" variant="h4" mb={0.4}>
            {order_info?.schedule_option && order_info?.schedule_option == ScheduleOptions.Delivery
              ? `Delivery Appointment`
              : order_info?.appointment_details
              ? `Pickup Appointment`
              : ''}
          </Typography>
          {order_info?.status == Status.Completed ? (
            <Box theme={theme} sx={{ display: 'flex', gap: 0.4 }}>
              <Typography theme={theme} component="span" variant="caption" mb={2}>
                The vehicle has been delivered
              </Typography>
            </Box>
          ) : (
            <Box theme={theme} sx={{ display: 'flex', gap: 0.4 }}>
              <Typography theme={theme} component="span" variant="caption" mb={2}>
                The time zone of this appointment is{' '}
                {order_info?.appointment_details?.time_zone ? dealer_profile_data?.time_zone : ''}
                . Need a different date or time?
              </Typography>
              <Link
                className="link"
                variant="caption"
                component={'a'}
                onClick={() => {
                  setOpenPopup(true)
                }}
              >
                Reschedule.
              </Link>
            </Box>
          )}

          <Grid theme={theme} className="container" container>
            <Grid
              theme={theme}
              item
              xl={4}
              lg={4}
              md={4}
              sm={4}
              xs={12}
              mt={1}
              className="appointment"
            >
              <Box theme={theme} className="text-center">
                <Icon className="appointment-icon" name="CalendarAppointmentIcon" />
                <Typography theme={theme} variant="caption" component="p" className="label">
                  {dateTime?.format('dddd')}
                </Typography>
                <Typography theme={theme} variant="subtitle1" component="h3">
                  {dateTime?.format('MMMM')} {dateTime?.format('DD')}
                </Typography>
              </Box>
            </Grid>
            <Grid
              theme={theme}
              item
              xl={4}
              lg={4}
              md={4}
              sm={4}
              xs={12}
              mt={1}
              className="appointment"
            >
              <Box theme={theme} className="text-center">
                <Icon className="appointment-icon" name="ClockIcon" />
                <Typography theme={theme} variant="caption" component="p" className="label">
                  {getTimePeriod()}
                </Typography>
                <Typography theme={theme} variant="subtitle1" component="h3">
                  {dateTime?.format('hh:mm')} {dateTime?.format('A')}
                </Typography>
              </Box>
            </Grid>
            <Grid
              theme={theme}
              item
              xl={4}
              lg={4}
              md={4}
              sm={4}
              xs={12}
              mt={1}
              className="appointment"
            >
              <Box theme={theme} className="text-center">
                <Icon className="appointment-icon" name="LocationIcon" />
                <Typography theme={theme} variant="caption" component="p" className="label">
                  At
                </Typography>
                <Typography theme={theme} variant="subtitle1" component="h3">
                  {order_info?.appointment_details?.address
                    ? [
                        order_info?.appointment_details?.address?.address_line_1,
                        order_info?.appointment_details?.address?.address_line_2,
                        order_info?.appointment_details?.address?.city,
                        order_info?.appointment_details?.address?.state_name
                      ]
                        .filter(Boolean)
                        .join(', ')
                    : dealerAddressDetails}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </DetailCard>
      ) : (
        <></>
      )}
      <RescheduleAppointmentDialog
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        order_detail={order_info}
        dealerProfileData={dealer_profile_data}
      ></RescheduleAppointmentDialog>
    </>
  )
}

export default PickupAppointment
