import { useTheme } from '@mui/material'
import { IConfirmationPopUp } from './ConfirmationDialog.type'
import { Dialog, Typography, Box, Button } from '@ntpkunity/controls'

export const ConfirmationDialog = <T extends any>({
  openPopUp,
  setPopUpState,
  confirmationText,
  confirmationTitle,
  onConfirm,
  onCancel,
  hideCancelButton,
  icon,
  primaryBtnText,
  secondaryBtnText,
  isButtonTypeDanger
}: IConfirmationPopUp<T>) => {
  const theme = useTheme()

  const onClose = () => {
    setPopUpState(false)
  }

  const onCancelClick = (e: any) => {
    onClose()
    onCancel(e)
  }

  const onConfirmClick = (e: any) => {
    setPopUpState(false)
    onConfirm(e)
  }

  return (
    <Dialog
      disablePortal
      size="xs"
      variant="confirmation"
      open={openPopUp}
      noFooter={false}
      className={'u-dialog-sm'}
      onCloseDialog={onClose}
      theme={theme}
      customFooter={
        <>
          {!hideCancelButton && (
            <Button
              theme={theme}
              secondary
              text={secondaryBtnText ? secondaryBtnText : 'Cancel'}
              onClick={onCancelClick}
            ></Button>
          )}
          <Button
            theme={theme}
            onClick={onConfirmClick}
            primary={isButtonTypeDanger ? false : true}
            text={primaryBtnText}
            danger={isButtonTypeDanger}
          ></Button>
        </>
      }
      children={
        <>
          <Box theme={theme} className="dialog-icon">
            {icon}
          </Box>

          <Box theme={theme}>
            <Typography theme={theme} mb={2} className="content-title" variant="h2" component="h2">
              {confirmationTitle}
            </Typography>

            <Typography theme={theme} className="text-muted" variant="body2" component="span">
              {confirmationText}
            </Typography>
          </Box>
        </>
      }
    />
  )
}
