import { styled } from '@mui/system'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const FileDragDropWrap = styled('div')(({ theme }) => ({
  '&.fileDragDropWrap': {
    marginTop: 16,
    '.order-detail': {
      border: '1px solid' + theme.palette.grey[100],
      borderRadius: theme.shape.borderRadius,
      overflowY: 'auto',
      height: '365px',
      '.file-row': {
        '.u-custom-file-upload': {
          width: '100%',
          marginRight: 16,
          marginBottom: '15px',
          '.root-drag-file': {
            width: '100%'
          }
        },
        '.file-caption': {
          color: theme.palette.grey[600],
          marginBottom: 15,
          textAlign: 'center'
        }
      },
      '&::-webkit-scrollbar': {
        width: 9,
        height: 9
      },
      '&::-webkit-scrollbar-track': {
        padding: '0 0px',
        backgroundColor: 'transparent'
      },
      '&::-webkit-scrollbar-thumb': {
        transition: 'all 0.3s',
        backgroundColor: 'rgba(0, 0, 0, 0.16)',
        borderRadius: 4,
        border: '3px solid transparent',
        backgroundClip: 'content-box'
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.16)'
      },
      '&:hover': {
        overflowY: 'overlay',
        '@-moz-document url-prefix()': {
          overflowY: 'auto'
        }
      }
    }
  }
}))

export const OrderApproved = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  ul: {
    paddingLeft: 18
  },
  '.row': {
    display: 'flex',
    alignItems: 'center'
  },
  '.justify-content-between': {
    justifyContent: 'space-between'
  },
  '.link': {
    color: theme.palette.text.primary,
    textDecoration: 'none'
  },
  '.line': {
    hr: {
      opacity: 0.35,
      margin: '24px 0'
    }
  },

  '.notification': {
    marginBottom: 16,
    width: '100%',
    '.mini-title': {
      fontSize: '10px',
      marginBottom: 8,
      color: theme.palette.grey[600],
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: theme.typography.h4.lineHeight
    },
    '.notify': {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      padding: 18,
      marginBottom: 8,
      color: theme.palette.grey[900],
      borderRadius: theme.shape.borderRadius,
      border: '1px solid' + theme.palette.grey[100],
      '&:hover': {
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.16)'
      },
      '.icon': {
        marginRight: 10
      },
      '.error': {
        color: theme.palette.error.main
      }
    }
  }
}))

export const Notification = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({}) => ({
  '.notification': {}
}))
