import IActions from '../configs'
import { Dispatch, ReducerAction } from 'react'

export const SET_DEALER_PROFILE_STATE = 'GET DEALER PROFILE DATA FROM SERVER'
export const SET_DEALER_AND_LENDER_INFO_STATE = 'GET DEALER AND LENDER DATA FROM SERVER'
export const SET_LENDER_INFO_STATE = 'GET LENDER DATA FROM SERVER'

export const initialState: any = {}

const reducer = (state: typeof initialState, action: IActions): typeof initialState => {
  switch (action.type) {
    case SET_DEALER_PROFILE_STATE:
      return action.payload as typeof initialState
    case SET_DEALER_AND_LENDER_INFO_STATE:
      return action.payload as typeof initialState
    case SET_LENDER_INFO_STATE:
      return action.payload as typeof initialState
  }
  return state
}

export const setDealerProfileData = (
  data: typeof initialState,
  dispatch: Dispatch<ReducerAction<typeof reducer>>
): void => {
  if (data) {
    dispatch({ type: SET_DEALER_PROFILE_STATE, payload: data })
  }
}

export const setDealerAndLenderInfo = (
  data: typeof initialState,
  dispatch: Dispatch<ReducerAction<typeof reducer>>
): void => {
  if (data) {
    dispatch({ type: SET_DEALER_AND_LENDER_INFO_STATE, payload: data })
  }
}

export const setLenderInfo = (
  data: typeof initialState,
  dispatch: Dispatch<ReducerAction<typeof reducer>>
): void => {
  if (data) {
    dispatch({ type: SET_LENDER_INFO_STATE, payload: data })
  }
}

export default reducer
