import { FC, useState } from 'react'
import { Stack, useTheme } from '@mui/material'
import {
  Typography,
  Box,
  Icon,
  Tooltip,
  ButtonGroup,
  Menu,
  Button,
  Dialog
} from '@ntpkunity/controls'
import { PageHeader, Divider } from '@components'
import { useNavigate } from 'react-router'
import { APP_ROUTES } from '@helpers/links'
import { styled } from '@mui/material/styles'
import { WorkQueueState } from '@helpers/enums'
import { useModal } from 'react-modal-hook'
import { CustomerOrderList } from '@app/index'
import CompareIcon from '../../public/assets/icons/compare-icon.svg'
import AffordabilityCheck from 'components/Affordability'

const Item = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1)
}))
const AffordabilityCheckDialog: any = styled('div')(() => ({
  '.MuiDialog-root.u-dialog.MuiModal-root': {
    left: 80,
    '.u-dialog-title': {
      position: 'absolute',
      width: '100%',
      top: -30,
      padding: 0,

      '.u-dialog-close svg path': {
        stroke: '#fff'
      }
    },
    '.MuiPaper-root.MuiDialog-paper': {
      maxWidth: '100%',
      overflow: 'initial'
    },
    '.MuiDialogContent-root.u-dialog-content': {
      padding: 0,
      overflow: 'initial',
      border: '0 solid transparent',
      '.top-bar': {
        display: 'none'
      }
    }
  },
  '.wrap': {
    height: 'calc(100vh - 47px) !important',
    borderRadius: '24px !important'
  }
}))
const WorkQueueHeader: FC<{
  total_results: number
  heading: string
  header_text: string
  state: WorkQueueState
  handleSearchClick: (state: WorkQueueState) => void
  handleChangeView: (state: WorkQueueState) => void
  showCreateOrderButton?: boolean
  disableButton?: boolean
  isCardView?: boolean
}> = ({
  total_results,
  heading,
  header_text,
  handleSearchClick,
  state,
  handleChangeView,
  showCreateOrderButton,
  disableButton,
  isCardView
}) => {
  const theme = useTheme()
  let navigate = useNavigate()
  const [openVdModal, closeVdModal] = useModal(
    () => <CustomerOrderList closeVdModal={closeVdModal} navigate={navigate} />,
    []
  )
  const [openAffoedibilityDialog, setOpenAffordabilityDialog] = useState(false)

  const handleOptionClick = (_event: any, _key: any, value: any) => {
    switch (value) {
      case 'Finance First':
        setOpenAffordabilityDialog(true)
        localStorage.setItem('closeModal', 'false')
        break
    }
  }

  return (
    <>
      <PageHeader className="main-page-header">
        <Box flexGrow={'1'} theme={theme}>
          <Typography theme={theme} variant="h2" component="h2" className="title">
            {heading}
          </Typography>
          {total_results > 0 && (
            <Typography theme={theme} variant="subtitle1" component="p" className="counter">
              {total_results}
            </Typography>
          )}
          <Typography theme={theme} variant="body2" component="span" className="caption">
            {header_text}
          </Typography>
        </Box>
        <Box
          className="action-area"
          flexShrink={0}
          display={'flex'}
          alignItems={'center'}
          theme={theme}
        >
          <Stack
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            spacing={2}
            className="stack"
            sx={{ mr: 2 }}
          >
            {heading === 'Orders' ? (
              <Item theme={theme}>
                <Tooltip title="Compare Orders" theme={theme} placement="top">
                  <Button
                    theme={theme}
                    className="btn-compare"
                    defaultBtn
                    disabled={disableButton}
                    iconText={<img src={CompareIcon} />}
                    onClick={() => {
                      openVdModal()
                    }}
                  />
                </Tooltip>
              </Item>
            ) : (
              <></>
            )}
            <Item theme={theme}>
              <Button
                theme={theme}
                defaultBtn
                iconText={<Icon name="SearchIcon" />}
                onClick={() => {
                  handleSearchClick(state)
                }}
              />
            </Item>
            <Item theme={theme}>
              <Button
                theme={theme}
                defaultBtn
                iconText={<Icon name={isCardView ? 'ListIcon' : 'GridIcon'} />}
                onClick={(_e) => {
                  handleChangeView(state)
                }}
              />
            </Item>
          </Stack>
          {showCreateOrderButton && (
            <ButtonGroup
              theme={theme}
              variant="contained"
              disabled={disableButton}
              children={
                <>
                  <Button
                    secondary
                    theme={theme}
                    text={'Create New Order'}
                    startIcon={
                      <>
                        <Icon name="AddIcon" />
                      </>
                    }
                    onClick={() => {
                      localStorage.setItem('closeModal', 'false')
                      localStorage.removeItem('step')
                      navigate(APP_ROUTES.CREATE_ORDER)
                    }}
                  />
                  <Menu
                    theme={theme}
                    options={[
                      {
                        optionText: 'Finance First',
                        optionValue: 'Finance First'
                      }
                    ]}
                    handleOptionClick={handleOptionClick}
                    render={(onMenuSelection) => (
                      <Button
                        theme={theme}
                        secondary
                        iconText={<Icon name="MoreIcon" />}
                        onClick={onMenuSelection}
                      />
                    )}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                  />
                </>
              }
            />
          )}
        </Box>
      </PageHeader>
      <AffordabilityCheckDialog>
        <Dialog
          className="dialog-with-border"
          theme={theme}
          title={''}
          open={openAffoedibilityDialog}
          onCloseDialog={() => {
            setOpenAffordabilityDialog(false)
          }}
          size="md"
          fullWidth
          disablePortal
          noFooter
        >
          <AffordabilityCheck setOpenAffordibilityDialog={setOpenAffordabilityDialog} />
        </Dialog>
      </AffordabilityCheckDialog>
    </>
  )
}

export default WorkQueueHeader
